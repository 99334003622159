import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Layout,
  Loading,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Autocomplete,
  Icon,
  Banner,
  Toast,
  Text,
  DropZone,
  Box,
  HorizontalStack,
  Button,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../components';

import './CustomerNew.scss';
import { SaveMinor, SearchMinor } from '@shopify/polaris-icons';
import axios, { AxiosError } from 'axios';
import { useUser } from '../../../utils/PrivateRoute';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { generateFiscalCode } from '../../../utils/fiscalcode';

interface ScanResultItem {
  label: string;
  value: string;
  confidence: number;
}

interface ScanResponse {
  status: 'success' | 'failed';
  data: {
    scan_results: ScanResultItem[];
  };
}

export function CustomerNew() {
  const navigate = useNavigate();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [scanSuccess, setScanSuccess] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [active, setActive] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [existError, setExistError] = useState<'fiscal_code' | 'user' | null>(null);

  const conflictErrorMessages = {
    fiscal_code: {
      title: 'Esiste già un cliente associato a questo codice fiscale',
      description: 'Si è pregati di controllare il codice fiscale se si desidera proseguire.',
    },
    user: {
      title: 'Esiste già un cliente associato a questo indirizzo email',
      description: "Si è pregati di controllare l'indirizzo email se si desidera proseguire.",
    },
  };

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  /**
   * Customer states
   */
  const [customerId, setCustomerId] = useState('');
  const [firstname, setFirstname] = useState(''); //Firstname used as both first name and company name
  const [lastname, setLastname] = useState(''); //Only used if private customer is selected
  const [language, setLanguage] = useState('it');

  const genderOptions = [
    { label: 'Uomo', value: 'male' },
    { label: 'Donna', value: 'female' },
  ];
  const [gender, setGender] = useState('male');
  const [birthDate, setBirthDate] = useState('');
  const [birthDateError, setBirthDateError] = useState({
    status: false,
    message: '',
  });
  const [birthCity, setBirthCity] = useState<{ label: string; value: string }>({ label: '', value: '' });
  const birthCityDeselectedOptions = useMemo(() => [], []);
  const [birthCityOptions, setBirthCityOptions] = useState<{ label: string; value: string }[]>([]);
  const [birthCitySelectedOptions, setBirthCitySelectedOptions] = useState<string[]>([]);
  const [birthCityInputValue, setBirthCityInputValue] = useState('');
  const [birthCityLoading, setBirthCityLoading] = useState(false);
  const [fiscalCode, setFiscalCode] = useState('');

  const [vat, setVat] = useState(''); //Only used if company customer is selected
  const [ateco, setAteco] = useState(''); //Only used if company customer is selected
  const [job, setJob] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [type, setType] = useState('private'); // Can be either 'private' or 'company'
  const [line, setLine] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('IT');
  const [province, setProvince] = useState('');

  const [countryOptions, setCountryOptions] = useState([]); // Used to load array of countries
  const [notes, setNotes] = useState('');

  const [dealer, setDealer] = useState('');

  /**
   * Empty fields
   */
  const [emptyFields, setEmptyFields] = useState({
    firstname: false,
    lastname: false,
    fiscalCode: false,
    vat: false,
    email: false,
  });

  /**
   * Errors
   */
  const [zipError, setZipError] = useState(false);

  // DropZone multipla: gestiamo un array di file
  const [documentFiles, setDocumentFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useState(false);
  const [scanError, setScanError] = useState(false);
  const [buttonSpinningScan, setButtonSpinningScan] = useState(false);

  /**
   * DropZone multiple
   */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[]) => {
    // Aggiungiamo i nuovi file a quelli già caricati
    setDocumentFiles((prev) => [...prev, ...acceptedFiles]);
    setScanError(false);
    setFileError(false);
  }, []);

  // Rimozione di un singolo file
  const handleRemoveFile = useCallback((fileToRemove: File) => {
    setDocumentFiles((prev) => prev.filter((file) => file !== fileToRemove));
    setScanError(false);
  }, []);

  /**
   * Scansione documenti multipli
   */
  const handleScanDocument = useCallback(async () => {
    if (documentFiles.length === 0) return;

    try {
      setButtonSpinningScan(true);
      setScanError(false);
      setScanSuccess(false);

      const formData = new FormData();
      documentFiles.forEach((f) => {
        formData.append('documents', f);
      });

      const response = await axios.post<ScanResponse>(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/id-scan`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      const data = response.data;
      if (data.status === 'success' && data.data.scan_results.length > 0) {
        const resultsMap = data.data.scan_results.reduce<Record<string, string>>((acc, item) => {
          acc[item.label] = item.value;
          return acc;
        }, {});

        if (resultsMap.FirstName) setFirstname(resultsMap.FirstName.charAt(0).toUpperCase() + resultsMap.FirstName.toLowerCase().slice(1));
        if (resultsMap.LastName) setLastname(resultsMap.LastName.charAt(0).toUpperCase() + resultsMap.LastName.toLowerCase().slice(1));
        if (resultsMap.DateOfBirth) {
          // Regex to extract the date of birth in the format DD/MM/YYYY
          const dateOfBirth = resultsMap.DateOfBirth.match(/(\d{2})\.(\d{2})\.(\d{4})/);
          if (dateOfBirth) {
            setBirthDate(`${dateOfBirth[3]}-${dateOfBirth[2]}-${dateOfBirth[1]}`);
          }
        }

        if (resultsMap.Sex) {
          const sex = resultsMap.Sex.toUpperCase();
          if (sex === 'M') setGender('male');
          else if (sex === 'F') setGender('female');
        }

        // Luogo di nascita
        if (resultsMap.PlaceOfBirth) {
          const indexOfProvince = resultsMap.PlaceOfBirth.indexOf('(');
          const endIndex = indexOfProvince !== -1 ? indexOfProvince : resultsMap.PlaceOfBirth.length;
          const formattedPlaceOfBirth = resultsMap.PlaceOfBirth.charAt(0).toUpperCase() + resultsMap.PlaceOfBirth.toLowerCase().slice(1, endIndex);

          const birthCityResponse = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/user/cadastral_codes/search/${formattedPlaceOfBirth}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          });

          setBirthCityOptions(birthCityResponse.data.data);

          const matchedOption = birthCityResponse.data.data.find((option: any) => {
            return option.label.toLowerCase().trim() === formattedPlaceOfBirth.toLowerCase().trim();
          });
          setBirthCity({
            label: matchedOption?.label || resultsMap.PlaceOfBirth,
            value: matchedOption?.value || resultsMap.PlaceOfBirth,
          });
          setBirthCityInputValue(matchedOption?.label || resultsMap.PlaceOfBirth);
          setBirthCitySelectedOptions([matchedOption?.value || resultsMap.PlaceOfBirth]);
        }

        // Codice Fiscale
        if (resultsMap.PersonalNumber) {
          setFiscalCode(resultsMap.PersonalNumber);
        }

        // Indirizzo
        if (resultsMap.Address) {
          setLine(resultsMap.Address);
        }

        setScanSuccess(true);
      } else {
        setScanError(true);
      }
    } catch (error: any) {
      console.error(error);
      setScanError(true);
    } finally {
      setButtonSpinningScan(false);
    }
  }, [documentFiles]);

  /**
   * Markup DropZone
   */
  const dropZoneMarkup = (
    <FormLayout.Group>
      <DropZone onDrop={handleDropZoneDrop} allowMultiple={true} accept="application/pdf,image/*" error={fileError}>
        {documentFiles.length > 0 ? (
          <Box padding="2">
            {documentFiles.map((file, index) => (
              <HorizontalStack key={index} gap="2" blockAlign="center">
                <Text as={'dd'}>{file.name}</Text>
                <Button onClick={() => handleRemoveFile(file)} size="slim" plain>
                  Rimuovi
                </Button>
              </HorizontalStack>
            ))}
          </Box>
        ) : (
          <DropZone.FileUpload actionHint="Trascina o clicca per caricare uno o più file" />
        )}
      </DropZone>
      {scanError && (
        <Box padding="2">
          <Text color="critical" as="span">
            Errore durante la scansione o nessun dato estratto.
          </Text>
        </Box>
      )}
    </FormLayout.Group>
  );

  /**
   * Handle discard
   */
  const handleDiscard = useCallback(() => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setPhone('');
    setVat('');
    setFiscalCode('');
    setNotes('');
    setType('private');
    setLine('');
    setCity('');
    setZip('');
    setCountry('');
    setIsDirty(true);

    // Reset scan files
    setDocumentFiles([]);
    setScanError(false);
  }, []);

  /**
   * Save data
   */
  const handleSave = useCallback(
    async (draft?: boolean) => {
      try {
        // Check firstname & lastname
        if (customerId && !draft && type === 'private' && (firstname === '' || lastname === '' || email === '')) {
          if (firstname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: true }));
          if (lastname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, lastname: true }));
          if (email === '') setEmptyFields((emptyFields) => ({ ...emptyFields, email: true }));

          setButtonSpinning(false);
          return;
        } else if (customerId && !draft && type === 'company' && (firstname === '' || fiscalCode === '' || vat === '' || email === '')) {
          if (firstname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: true }));
          if (fiscalCode === '') setEmptyFields((emptyFields) => ({ ...emptyFields, fiscalCode: true }));
          if (vat === '') setEmptyFields((emptyFields) => ({ ...emptyFields, vat: true }));
          if (email === '') setEmptyFields((emptyFields) => ({ ...emptyFields, email: true }));

          setButtonSpinning(false);
          return;
        }

        // Check birth date
        if (birthDate && dayjs(birthDate).isAfter(dayjs())) {
          setBirthDateError({
            status: true,
            message: 'La data di nascita non può essere futura',
          });
        }

        if (draft) {
          // If the email is not a valid one return until the user completes the email
          if (RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/).test(email) === false) {
            return;
          }
          setIsSavingDraft(true);
        } else {
          setButtonSpinning(true);
        }

        // Prepare form data
        const formData = new FormData();

        // Append files
        if (documentFiles.length > 0) {
          documentFiles.forEach((file) => {
            formData.append('documents', file);
          });
        }

        // Append data
        formData.append('customer_id', customerId);
        formData.append('draft', draft ? 'true' : 'false');
        formData.append('name', firstname);
        formData.append('lastname', lastname);
        formData.append('email', email.toLowerCase());
        formData.append('fiscal_code', fiscalCode);
        formData.append('birth_date', birthDate);
        formData.append('birth_city', JSON.stringify(birthCity));
        formData.append('gender', type === 'private' ? gender : '');
        formData.append('vat', vat);
        formData.append('ateco', ateco);
        formData.append('job', job);
        formData.append('type', type);
        formData.append('phone', phone);
        formData.append('line', line);
        formData.append('city', city);
        formData.append('zip', zip);
        formData.append('country', country);
        formData.append('notes', notes);
        formData.append('organization_id', dealer);

        if (!draft) {
          debouncedSave.cancel();
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/new`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          if (!draft) {
            setIsDirty(false);
            setActive(true);
            setTimeout(() => {
              navigate(`/customers/${data.data._id}`);
            }, 3000);
          } else {
            setCustomerId(data.data._id);
            setIsSavingDraft(false);
          }
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;
        const data: any = axiosError.response?.data;
        if (status === 409) {
          setExistError(data.param as 'fiscal_code' | 'user');
        } else {
          setSaveError(true);
        }
      } finally {
        setButtonSpinning(false);
        setIsSavingDraft(false);
      }
    },
    [
      customerId,
      firstname,
      type,
      lastname,
      email,
      gender,
      birthCity,
      birthDate,
      fiscalCode,
      vat,
      ateco,
      job,
      phone,
      line,
      city,
      zip,
      country,
      notes,
      dealer,
      documentFiles,
    ],
  );

  /**
   * Handle save draft
   */

  // Using memo to avoid creating a new debounced function on every render
  const debouncedSave = useMemo(() => debounce(() => handleSave(true), 2500), [handleSave]);

  useEffect(() => {
    // Save draft only if email and firstname are not empty and when the user is completing the other fields
    if (firstname && email) {
      // Lastname is required only if type is private
      if (type === 'private' && lastname === '') {
        return;
      }
      debouncedSave();
    }

    return () => {
      debouncedSave.cancel();
    };
  }, [email, firstname, type, lastname, debouncedSave]);

  /** Handler */
  const handleFirstnameChange = useCallback(
    (e: string) => {
      setFirstname(e);
      if (emptyFields.firstname) setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: false }));
    },
    [emptyFields.firstname],
  );

  const handleLastnameChange = useCallback(
    (e: string) => {
      setLastname(e);
      if (emptyFields.lastname) setEmptyFields((emptyFields) => ({ ...emptyFields, lastname: false }));
    },
    [emptyFields.lastname],
  );

  // Fiscal code
  const handleFiscalCodeChange = useCallback(
    (e: string) => {
      setFiscalCode(e);
      if (emptyFields.fiscalCode) setEmptyFields((emptyFields) => ({ ...emptyFields, fiscalCode: false }));
    },
    [emptyFields.fiscalCode],
  );

  /**
   * Handle generate fiscal code
   */
  const handleGenerateFC = useCallback(() => {
    // Check if all fields are filled
    let isFilled = true;
    if (firstname === '') isFilled = false;
    if (lastname === '') isFilled = false;
    if (birthDate === '') isFilled = false;
    if (!birthCity?.value) isFilled = false;
    if (gender === '') isFilled = false;

    if (isFilled && birthCity?.value) {
      // Generate fiscal code italy
      const computedFiscalCode = generateFiscalCode(firstname, lastname, new Date(birthDate), birthCity.value, gender);
      setFiscalCode(computedFiscalCode);
    } else {
      alert('Compila tutti i campi per generare il codice fiscale');
      // TODO: show error
    }
  }, [firstname, lastname, birthDate, birthCity, gender]);

  // Gender
  const handleGenderChange = useCallback((e: any) => {
    setGender(e);
  }, []);

  // Email
  const handleEmailChange = useCallback(
    (e: string) => {
      if (emptyFields.email) setEmptyFields((emptyFields) => ({ ...emptyFields, email: false }));
      setEmail(e.toLowerCase());
    },
    [emptyFields.email],
  );

  // Phone
  const handlePhoneChange = useCallback((e: string) => {
    setPhone(e);
  }, []);

  // Vat
  const handleVatChange = useCallback(
    (e: string) => {
      if (emptyFields.vat) setEmptyFields((emptyFields) => ({ ...emptyFields, vat: false }));

      // Allow only numbers
      if (e.match(/^[0-9]*$/)) {
        setVat(e);
      }
    },
    [emptyFields.vat],
  );

  const handleAtecoChange = useCallback((e: any) => {
    setAteco(e);
  }, []);

  const handleTypeChange = useCallback(
    (e: any) => {
      // Reset empty fields depending on type
      if (type === 'private') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: false, lastname: false, email: false }));
      } else {
        setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: false, fiscalCode: false, vat: false, email: false }));
      }

      setType(e);
    },
    [type],
  );

  const handleLanguageChange = useCallback((e: any) => {
    if (e === 'it' || e === 'en' || e === 'fr' || e === 'es' || e === 'de') {
      setLanguage(e);
    }
  }, []);

  // Line
  const handleLineChange = useCallback((e: string) => {
    setLine(e);
  }, []);

  // City
  const handleCityChange = useCallback((e: string) => {
    setCity(e);
  }, []);

  // Zip
  const handleZipChange = useCallback(
    (e: string) => {
      if (zipError) {
        setZipError(false);
      }

      // Allow only numbers
      if (e.match(/^[0-9]*$/)) {
        setZip(e);
      }

      // Allow only 5 numbers
      if (e.length > 5) {
        setZip(e.substring(0, 5));
      }
    },
    [zipError],
  );

  // Country
  const handleCountryChange = useCallback((e: any) => {
    setCountry(e);
  }, []);

  const handleNotesChange = useCallback((e: string) => {
    setNotes(e);
  }, []);

  // Used to set type of customer options
  const options = [
    { label: 'Privato', value: 'private' },
    { label: 'Azienda', value: 'company' },
  ];

  // Language
  const languages = [
    { label: 'Italiano', value: 'it' },
    { label: 'Inglese', value: 'en' },
    { label: 'Francese', value: 'fr' },
    { label: 'Spagnolo', value: 'es' },
    { label: 'Tedesco', value: 'de' },
  ];

  // Birth date
  const handleBirthDateChange = useCallback((e: string) => {
    const date = dayjs(e);

    // Check if the date is in the past
    if (date.isAfter(dayjs())) {
      return;
    }

    setBirthDate(e);
  }, []);

  // Birth City
  const updateBirthCityText = useCallback(
    (value: string) => {
      setBirthCityInputValue(value);

      if (!birthCityLoading) {
        setBirthCityLoading(true);
      }

      if (value === '') {
        setBirthCityOptions(birthCityDeselectedOptions);
        setBirthCityLoading(false);
        return;
      }

      // When the user has typed at least 3 characters or the id scan is started, request
      // the list from the server
      if (value.length < 3) {
        setBirthCityLoading(false);
        return;
      } else {
        // Fetch birth city options
        setBirthCityLoading(true);
        axios
          .get(`${process.env.REACT_APP_API_URL ?? '/api'}/user/cadastral_codes/search/${value}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          })
          .then((response) => {
            const data = response.data;

            if (data.status === 'success') {
              setBirthCityOptions(data.data);
            }
          })
          .catch((error) => {
            const axiosError = error as AxiosError;
            console.log(axiosError);
          })
          .finally(() => {
            setBirthCityLoading(false);
          });
      }
    },
    [birthCityOptions, birthCityLoading, birthCityDeselectedOptions],
  );

  const updateBirthCitySelection = useCallback(
    (selected: string[]) => {
      const selectedText = selected.map((selectedItem) => {
        const matchedOption = birthCityOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });
      setBirthCitySelectedOptions(selected);
      setBirthCity({
        label: selectedText[0] || '',
        value: selected[0] || '',
      });
      setBirthCityInputValue(selectedText[0] || '');
    },
    [birthCityOptions],
  );

  /**
   * Contextual save bar
   **/
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: () => handleSave(false),
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Search organizations
   */
  const [selectedOrgOptions, setSelectedOrgOptions] = useState<string[]>([]);
  const [inputOrgValue, setInputOrgValue] = useState('');
  const [deselectedOrgOptions, setDeselectedOrgOptions] = useState<{ label: string; value: string }[]>([]);
  const [orgOptions, setOrgOptions] = useState<{ label: string; value: string }[]>([]);
  const [orgLoading, setOrgLoading] = useState(false);

  /**
   * Search jobs
   */
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);

  /**
   * Search ateco
   */
  const [selectedAtecoOptions, setSelectedAtecoOptions] = useState([]);
  const [inputAtecoValue, setInputAtecoValue] = useState('');
  const [deselectedAtecoOptions, setDeselectedAtecoOptions] = useState([]);
  const [atecoOptions, setAtecoOptions] = useState([]);

  /**
   * Fetch data:
   * - organizations
   * - countries
   * - jobs
   * - ateco codes
   */
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/organizations`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });

        const data = response.data;

        if (data.status === 'success') {
          const tmp = [];
          for (const item of data.data) {
            tmp.push({ value: item._id as string, label: item.name as string });
          }
          setDeselectedOrgOptions(tmp);
          setOrgOptions(tmp);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/countries`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCountryOptions(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;
        const data: any = axiosError.response?.data;
      } finally {
        setIsLoading(false);
      }
    };
    fetchCountries();
    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/jobs', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (data.status === 'success') {
          const tmp = [];
          for (const item of data.data) {
            tmp.push({ value: item._id, label: item.label });
          }
          // @ts-ignore
          setDeselectedOptions(tmp);
          // @ts-ignore
          setJobOptions(tmp);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchAteco = async () => {
      try {
        setIsLoading(true);
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/ateco', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          const tmp = [];
          for (const item of response.data) {
            tmp.push({ value: item._id, label: item.label });
          }
          // @ts-ignore
          setDeselectedAtecoOptions(tmp);
          // @ts-ignore
          setAtecoOptions(tmp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchJobs().then(() => {
      fetchOrganizations().then(() => {
        fetchCountries();
      });
    });
    // fetchAteco();
  }, []);

  /**
   * Autocomplete Controls
   */

  /** Organization */
  const updateOrgText = useCallback(
    (value: any) => {
      setInputOrgValue(value);

      if (!orgLoading) {
        setOrgLoading(true);
      }

      if (value === '') {
        setOrgOptions(deselectedOrgOptions);
        setOrgLoading(false);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOrgOptions.filter((option) => {
        return option.label.match(filterRegex);
      });
      setOrgOptions(resultOptions);
      setOrgLoading(false);
    },
    [deselectedOrgOptions, orgLoading],
  );

  const updateOrgSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = orgOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption;
      });
      setSelectedOrgOptions(selected);
      setInputOrgValue(selectedValue[0].label);
      setDealer(selectedValue[0].value);
    },
    [orgOptions],
  );

  const orgTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateOrgText}
      label="Organizzazione"
      value={inputOrgValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  const orgEmptyState = (
    <React.Fragment>
      <Icon source={SearchMinor as any} />
      <div style={{ textAlign: 'center' }}>Non sono presenti risultati</div>
    </React.Fragment>
  );

  /** Job */
  const updateText = useCallback(
    (value: any) => {
      setInputValue(value);

      if (value === '') {
        setJobOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setJobOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = jobOptions.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue[0].label);
      // handleCustomerNameChange(selected);
      // handleCustomerIdChange(selectedValue[0].value);
      setJob(selectedValue[0].value);
    },
    [jobOptions],
  );

  const jobTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      label="Professione"
      value={inputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  /** Ateco */
  const updateAtecoText = useCallback(
    (value: any) => {
      setInputAtecoValue(value);

      if (value === '') {
        setAtecoOptions(deselectedAtecoOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedAtecoOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setAtecoOptions(resultOptions);
    },
    [deselectedAtecoOptions],
  );

  const updateAtecoSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = atecoOptions.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedAtecoOptions(selected);
      setInputAtecoValue(selectedValue[0].label);
      // handleCustomerNameChange(selected);
      // handleCustomerIdChange(selectedValue[0].value);
      setAteco(selectedValue[0].value);
    },
    [atecoOptions],
  );

  const atecoTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateAtecoText}
      label="ATECO"
      value={inputAtecoValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  /** Birth city autocomplete */
  const birthCityTextField = (
    <Autocomplete.TextField
      label="Luogo di nascita"
      autoComplete="off"
      value={birthCityInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
      onChange={updateBirthCityText}
    />
  );

  const birthCityEmptyState = (
    <React.Fragment>
      <Icon source={SearchMinor as any} />
      <div style={{ textAlign: 'center' }}>Non sono presenti risultati</div>
    </React.Fragment>
  );

  /**
   * Saving draft markup
   */
  const savingDraftMarkup = isSavingDraft && (
    <div className="saving-indicator">
      <Icon source={SaveMinor as any} color="base" />
      <Text as="p">Salvataggio...</Text>
    </div>
  );

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="Il cliente è stato creato con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const existErrorMarkup = existError && (
    <Layout.Section>
      <Banner title={conflictErrorMessages[existError].title} status="critical" onDismiss={() => setExistError(null)}>
        <p>{conflictErrorMessages[existError].description}</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title="Cliente" backAction={{ content: 'Clienti', url: '/customers' }} titleMetadata={savingDraftMarkup}>
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {existErrorMarkup}

        {/* Sezione Scansione (DropZone multipla) */}
        <Layout.AnnotatedSection title="Scansiona Carta d'identità" description="Carica uno o più file (es. fronte e retro), poi avvia la scansione.">
          <LegacyCard sectioned>
            {dropZoneMarkup}

            {documentFiles.length > 0 && (
              <div style={{ marginTop: '1rem' }}>
                <Button fullWidth primary onClick={handleScanDocument} loading={buttonSpinningScan}>
                  {buttonSpinningScan ? 'Scansione in corso...' : scanSuccess ? 'Scansione completata con successo!' : 'Scansiona Documenti'}
                </Button>
              </div>
            )}
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Panoramica cliente */}
        <Layout.AnnotatedSection title="Panoramica cliente">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Select label="Tipologia cliente" options={options} onChange={handleTypeChange} value={type} />
              </FormLayout.Group>
              <FormLayout.Group>
                {/* Logic to display either private's or company's naming info */}
                {type === 'private' && (
                  <TextField
                    autoComplete="on"
                    type="text"
                    label="Nome"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={emptyFields.firstname && 'Il nome è obbligatorio'}
                  />
                )}
                {type === 'private' && (
                  <TextField
                    autoComplete="on"
                    type="text"
                    label="Cognome"
                    value={lastname}
                    onChange={handleLastnameChange}
                    error={emptyFields.lastname && 'Il cognome è obbligatorio'}
                  />
                )}
                {type === 'company' && (
                  <TextField
                    autoComplete="on"
                    type="text"
                    label="Ragione Sociale"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={emptyFields.firstname && 'La regione sociale è obbligatoria'}
                  />
                )}
              </FormLayout.Group>
              {type === 'private' && (
                <FormLayout.Group>
                  <TextField autoComplete="on" type="date" label="Data di nascita" value={birthDate} onChange={handleBirthDateChange} />
                  <Autocomplete
                    options={birthCityOptions}
                    selected={birthCitySelectedOptions}
                    onSelect={updateBirthCitySelection}
                    textField={birthCityTextField}
                    emptyState={birthCityEmptyState}
                    loading={birthCityLoading}
                  />
                </FormLayout.Group>
              )}
              <FormLayout.Group>
                {type === 'private' && <Select label="Sesso" options={genderOptions} value={gender} onChange={handleGenderChange} />}
                <TextField
                  autoComplete="on"
                  type="text"
                  label="Codice Fiscale"
                  value={fiscalCode}
                  onChange={handleFiscalCodeChange}
                  error={emptyFields.fiscalCode && 'Il codice fiscale è obbligatorio'}
                  labelAction={
                    type === 'private'
                      ? {
                          content: 'Genera',
                          onAction: handleGenerateFC,
                        }
                      : undefined
                  }
                />
              </FormLayout.Group>
              <FormLayout.Group>
                {type === 'private' && (
                  <Autocomplete options={jobOptions} selected={selectedOptions} onSelect={updateSelection} textField={jobTextField} loading={isLoading} />
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                {/* Logic checks if vat is to be displayed */}
                {type === 'company' && (
                  <TextField
                    autoComplete="on"
                    type="text"
                    label="Partita IVA"
                    value={vat}
                    onChange={handleVatChange}
                    error={emptyFields.vat && 'La P.IVA è obbligatoria'}
                  />
                )}
                {type === 'company' && (
                  <Autocomplete options={atecoOptions} selected={selectedAtecoOptions} onSelect={updateAtecoSelection} textField={atecoTextField} />
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  error={emptyFields.email && "L'email è obbligatoria"}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="tel" label="Numero di telefono" value={phone} onChange={handlePhoneChange} />
                <Select label="Lingua" options={languages} onChange={handleLanguageChange} value={language} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Indirizzo */}
        <Layout.AnnotatedSection title="Indirizzo" description="L'indirizzo principale di questo cliente">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Select label="Stato" options={countryOptions} onChange={handleCountryChange} value={country} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Indirizzo" value={line} onChange={handleLineChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="CAP" value={zip} onChange={handleZipChange} error={zipError && 'Controllare il CAP'} />
                <TextField autoComplete="on" type="text" label="Città" value={city} onChange={handleCityChange} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Organizzazione Partner */}
        {user?.role === 'admin' && (
          <Layout.AnnotatedSection
            title="Partner"
            description="Il partner al quale abbinare il cliente. Se non si seleziona nulla, questo verrà abbinato a NANO."
          >
            <LegacyCard sectioned>
              <FormLayout>
                <FormLayout.Group>
                  <Autocomplete
                    options={orgOptions}
                    selected={selectedOrgOptions}
                    onSelect={updateOrgSelection}
                    textField={orgTextField}
                    emptyState={orgEmptyState}
                    loading={birthCityLoading}
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard>
          </Layout.AnnotatedSection>
        )}

        {/* Note */}
        <Layout.AnnotatedSection title="Note">
          <LegacyCard sectioned>
            <TextField autoComplete="on" type="text" label="Note Sul Cliente" value={notes} onChange={handleNotesChange} multiline={6} />
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}
