import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './utils/PrivateRoute';
import routes from './routes';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Private routes */}
          <Route element={<PrivateRoute />}>
            {routes.map((route, idx) => {
              return <Route key={idx} path={route.path} element={<route.component />} />;
            })}
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
