import { Company } from './company';
import { Product } from './product';

/**
 * Option type
 */
export type Option = {
  /** The option name */
  name: string;
  /** The option value: price || multiplicator */
  type: 'price' | 'multiplicator';
  value: string;
  /** The position in results */
  position: number;
};

// Variant file type enum
export enum VariantFileType {
  INFORMATION_SET = 'information_set',
  PRECONTRACTUAL_INFORMATION = 'precontractual_information',
}

/**
 * Group policy type
 */
export type GroupPolicyMetadata = {
  /** The group policy broker */
  broker?: string;
  /** The group policy brach name */
  branch?: string;
  /** The group policy number */
  number?: string;
  /** The group policy inclusion number */
  inclusion_number_prefix?: string;
};

/**
 * Email template type
 */
export type EmailTemplate = {
  /** The email template label */
  label: 'quote' | 'policy' | 'renewal';
  /** The email template value */
  value: string;
};

/**
 * Quotation field
 *
 * @description Used to define quotation fields
 */
export type QuotationField = {
  _id?: string;
  /** The quotation field name */
  name: string;
  type: 'select' | 'percentage' | 'number' | 'boolean' | 'text' | 'currency' | 'array' | 'date';
  /** The quotation field value: can be null if type is text, number or boolean, whereas
   * it can be an array of objects if type is select */
  values?: Array<{ label: string; value: string }> | QuotationField[];
  /** The quotation field result modifier type */
  result_modifier_type: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none';
  /** The quotation field result modifier value */
  result_modifier_value?: string;
  /** The api field name - if variant type is api */
  api_field_name?: string;
  /** The position in result */
  position: number;
};

/**
 * Pricing table type
 */
export type PricingTable = {
  /** The pricing table row */
  row: string;
  /** The pricing table row labels */
  row_labels: string[];
  /** The pricing table column */
  column: string;
  /** The pricing table column labels */
  column_labels: string[];
  /** The pricing table values */
  values: number[][];
  /** If the pricing is a range */
  is_range?: boolean;
};

/**
 * Commission type
 */
export type Commission = {
  /** The commission type */
  value_type: 'fixed_amount' | 'percentage';
  /** The commission value */
  value: string;
};

/**
 * Management price type
 */
export type ManagementPrice = {
  /** The management price type */
  value_type: 'fixed_amount' | 'percentage';
  /** The management price value */
  value: number;
  /** The field on which the management price is computed */
  value_field: 'total' | 'subtotal';
};

/**
 * Pricing rule type
 */
export type PricingRule = {
  /** The pricing rule name */
  name: 'minimum_price';
  /** The pricing rule type */
  type: 'fixed_amount' | 'percentage';
  /** The pricing rule value, to be converted to number */
  value: string;
  /** The position in results */
  position: number;
};

/**
 * Warranty type
 */
export type Warranty = {
  _id?: string;
  /** The warranty name */
  name: string;
  /** The warranty description */
  description?: string;
  /** The warranty result modifier type */
  result_modifier_type: 'fixed_amount' | 'percentage' | 'none';
  /** The warranty result modifier value */
  result_modifier_value?: string;
  /** The warranty value */
  value: string;
  /** The warranty deductible (franchigia) */
  deductible: string;
  /** If the warranty is included */
  included: boolean;
  /** The position in results */
  position: number;
};

/**
 * Renewal type
 */
export type Renewal = {
  /** Whether the renewal is automatic  */
  automatic?: boolean;
  /** Whether the renewal needs confirmation  */
  to_be_confirmed?: boolean;
};

/**
 * Variant file type
 */
export type VariantFile = {
  /** The file name stored */
  key: string;
  /** The file original name */
  title: string;
  /** The file type */
  file_type?: VariantFileType;
};

/**
 * Shared notification type
 */
type SharedNotification = {
  /** The email list */
  email_list: string[];
};

/**
 * Blocking conditions type
 */
export enum RuleTypes {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  IS_BEFORE = 'IS_BEFORE',
  IS_AFTER = 'IS_AFTER',
}

export type TCondition = {
  rule: RuleTypes;
  value: string;
};

export type TBlockingCondition = {
  field: string;
  conditions: TCondition[];
};

export const blockingConditionsRuleOptions = [
  {
    label: 'Uguale a',
    value: RuleTypes.EQUAL,
  },
  {
    label: 'Diverso da',
    value: RuleTypes.NOT_EQUAL,
  },
  {
    label: 'Maggiore di',
    value: RuleTypes.GREATER_THAN,
  },
  {
    label: 'Minore di',
    value: RuleTypes.LESS_THAN,
  },
  {
    label: 'Maggiore o uguale a',
    value: RuleTypes.GREATER_THAN_OR_EQUAL,
  },
  {
    label: 'Minore o uguale a',
    value: RuleTypes.LESS_THAN_OR_EQUAL,
  },
  {
    label: 'Prima di',
    value: RuleTypes.IS_BEFORE,
  },
  {
    label: 'Dopo di',
    value: RuleTypes.IS_AFTER,
  },
];

/**
 * Variant type
 */
export type Variant = {
  _id: any;
  /** The parent product of the variant */
  product: any | Product;
  /** The variant name */
  name: string;
  /** The variant description */
  description?: string;
  /** The variant coverages */
  coverages?: string[];
  /** The variant company */
  company: any | Company;
  /** Type define how the price is computed */
  type: 'api' | 'basic';
  /** The corresponding api product id */
  api_id?: string;
  /** The variant price */
  price?: number;
  /** The variant sale price */
  sale_price?: number;
  /** The variant warranties */
  warranties?: Warranty[];
  /** The variant shared notification object, composed of email_list and template */
  shared_notification?: SharedNotification;
  /** Whether the variant has blocking conditions */
  has_blocking_conditions?: boolean;
  /** The variant blocking condition */
  blocking_conditions?: TBlockingCondition[];
  /** The automation bot */
  has_automation_bot?: boolean;
  automation_name?: string;
  /** The flag indicating wheter the variant is a group policy */
  group_policy: boolean;
  /** The group policy metadata */
  group_policy_metadata?: GroupPolicyMetadata;
  /** The flag indicating wheter the variant has a direct emission */
  direct_emission: boolean;
  /** The flag indicating if the product can be sold with other products */
  individual_sale: boolean;
  /** If the product can be sold without other products, this is the field indicating the email templates */
  email_templates?: EmailTemplate[];
  /** The flag indicating wheter the variant support a quotation or not */
  quotation: boolean;
  /** The variant quotation field */
  quotation_fields?: QuotationField[];
  /** The pricing table */
  pricing_table?: PricingTable;
  /** The variant commission available for the broker */
  commission?: Commission;
  /** The variant management price */
  management_price?: ManagementPrice;
  /** Pricing rules */
  pricing_rules?: PricingRule[];
  /** Whether the variant needs to be quoted */
  to_be_quoted?: boolean;
  /** The variant renewal */
  renewal?: Renewal;
  /** The variant files */
  files?: VariantFile[];
  /** System information */
  date_created: Date;
  date_updated: Date;
  bank_transfer: boolean;
  /** The flag indicates if the notification for the user are disabled for the variant */
  disable_notifications: boolean;
};
