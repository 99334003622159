import React, { useCallback, useState } from 'react';
import { ActionList, TopBar } from '@shopify/polaris';

import { CircleRightMajor, ProfileMajor } from '@shopify/polaris-icons';

import logo from '../../logo_bw.svg';

import { getInitials } from '../../utils/Common';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export function TopBarMarkup({ handleMobileNavigation, user }: any) {
  const navigate = useNavigate();

  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const handleSearchFieldChange = useCallback((value: string) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);
  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
    handleMobileNavigation(mobileNavigationActive);
  }, [handleMobileNavigation, mobileNavigationActive]);
  const toggleUserMenuActive = useCallback(() => setUserMenuActive((userMenuActive) => !userMenuActive), []);

  /**
   * Logout
   */
  const logout = async () => {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Remove token from localstorage
        localStorage.removeItem('mb__access_token');

        // Redirect to login page
        window.location.href = '/';
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userMenuActions = [
    {
      items: [
        {
          content: 'Account',
          icon: ProfileMajor as any,
          onAction: async () => navigate('/settings/account'),
        },
      ],
    },
    {
      items: [
        {
          content: 'Logout',
          icon: CircleRightMajor as any,
          onAction: async () => await logout(),
        },
      ],
    },
  ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={user.lastname ? `${user.name} ${user.lastname}` : user.name}
      initials={getInitials(user.lastname ? `${user.name} ${user.lastname}` : user.name)}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
      accessibilityLabel="User menu"
    />
  );

  // const searchResultsMarkup = <ActionList items={[{ content: 'NANO Platform help center' }]} />;

  // const searchFieldMarkup = <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Cerca" />;

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      // searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      // searchResults={searchResultsMarkup}
      // onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
      contextControl={
        <div className="ContextControl">
          <img height={40} src={logo} />
        </div>
      }
      searchResultsOverlayVisible
    />
  );

  return topBarMarkup;
}
