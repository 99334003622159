import { useCallback, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, LegacyCard, FormLayout, Select, LegacyStack, TextField, Text, Card, Tag } from '@shopify/polaris';
import { DeleteMinor, DragHandleMinor, PagePlusMajor } from '@shopify/polaris-icons';
import { QuotationField } from '../../../../types';

interface Props {
  id: string;
  options: any[];
  quotationItem?: QuotationField;
  quotationItemOptions?: Array<{ label: string; value: string }>;
  onDelete: (id: string) => void;
  onSave?: (
    id: string,
    name: string,
    type: 'select' | 'percentage' | 'number' | 'boolean' | 'text' | 'currency' | 'array' | 'date',
    result_modifier_type: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none',
    values?: string,
    result_modifier_value?: string,
    api_field_name?: string,
  ) => void;
  onUpdate?: (
    _id: string,
    id: string,
    name: string,
    type: 'select' | 'percentage' | 'number' | 'boolean' | 'text' | 'currency' | 'array' | 'date',
    result_modifier_type: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none',
    values?: string,
    result_modifier_value?: string,
    api_field_name?: string,
  ) => void;
  hasPricingTable?: boolean;
  setHasPricingTable?: (value: boolean) => void;
}

export function SortableItemQuotation(props: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [name, setName] = useState(props.quotationItem?.name || '');

  const [values, setValues] = useState<{ label: string; value: string }[]>((props.quotationItem?.values as { label: string; value: string }[]) ?? []);
  const [subMetadata, setSubMetadata] = useState((props.quotationItem?.values as QuotationField[]) ?? []);
  const [metadataValues, setMetadataValues] = useState<{ label: string; value: string }[]>([]);

  const [type, setType] = useState(props.quotationItem?.type || props.options[0].value);
  const [resultModifierValue, setResultModifierValue] = useState(props.quotationItem?.result_modifier_value || '0');
  const [api_field_name, setApiFieldName] = useState(props.quotationItem?.api_field_name || '');
  const [isEditing, setIsEditing] = useState(props.quotationItem === undefined);

  const [resultModifierType, setResultModifierType] = useState(props.quotationItem?.result_modifier_type || 'none');
  const resultModifierTypeOptions = [
    { label: 'Importo Fisso', value: 'fixed_amount' },
    { label: 'Percentuale', value: 'percentage' },
    { label: 'Tabella', value: 'pricing_table' },
    { label: 'Nessuno', value: 'none' },
  ];

  const [emptyFields, setEmptyFields] = useState({
    name: false,
    resultModifierValue: false,
  });

  // Select array
  const [newSelectOption, setNewSelectOption] = useState({
    label: '',
    value: '',
  });
  const [newMetadataSelectOption, setNewMetadataSelectOption] = useState({
    label: '',
    value: '',
  });
  const [selectOptionError, setSelectOptionError] = useState({
    label: false,
    value: false,
  });

  const onDelete = () => {
    props.onDelete(props.id);
  };

  const onSelectChange = useCallback((value: any) => {
    setType(value);

    // If text is selected, clear value
    // if (value === 'text') {
    //   setValue('');
    // }
  }, []);

  // Name change handler
  const onNameChange = useCallback(
    (value: string) => {
      if (emptyFields.name) {
        setEmptyFields({ ...emptyFields, name: false });
      }
      setName(value);
    },
    [emptyFields.name],
  );

  // Result modifier type change handler
  const onResultModifierTypeChange = useCallback(
    (value: any) => {
      // If the type is set to select, use the values array (label, value) to populate the resultModifierValue
      if (type === 'select' && value === 'pricing_table') {
        const tmp = values.map((option) => option.value).join(',');
        setResultModifierValue(tmp);
      }

      setResultModifierType(value);
    },
    [values],
  );

  // Result modifier value change handler
  const onResultModifierValueChange = useCallback((value: any) => {
    setResultModifierValue(value);
  }, []);

  // Api field name change handler
  const onApiFieldNameChange = useCallback((value: any) => {
    setApiFieldName(value);
  }, []);
  /**
   * Save handler
   */
  const handleSave = () => {
    let errFlag = false;

    // Check if name is empty
    if (name === '') {
      setEmptyFields({
        ...emptyFields,
        name: true,
      });
      errFlag = true;
    }

    if (resultModifierValue === '' && resultModifierType !== 'none') {
      setEmptyFields({
        ...emptyFields,
        resultModifierValue: true,
      });
      errFlag = true;
    }

    if (errFlag) {
      return;
    }

    // Save data if quotationItem is undefined otherwise update data
    if (props.quotationItem === undefined) {
      if (props.onSave !== undefined)
        props?.onSave(
          props.id,
          name,
          type,
          resultModifierType,
          (type === 'array' ? subMetadata : JSON.stringify(values)) as any,
          resultModifierValue,
          api_field_name,
        );
    } else {
      if (props.onUpdate !== undefined && props.quotationItem._id !== undefined) {
        props?.onUpdate(
          props.quotationItem._id,
          props.id,
          name,
          type,
          resultModifierType,
          (type === 'array' ? subMetadata : JSON.stringify(values)) as any,
          resultModifierValue,
          api_field_name,
        );
      }
    }

    // Set hasPricingTable to true if resultModifierType is pricing_table
    if (resultModifierType === 'pricing_table' && props.setHasPricingTable !== undefined) {
      props.setHasPricingTable(true);
    }

    // Change component to display mode
    setIsEditing(false);
  };

  const handleAddSubMetadata = () => {
    // @ts-ignore
    setSubMetadata([...subMetadata, { name: '', api_field_name: '', type: 'text', result_modifier_type: 'none' }]);
  };

  // @ts-ignore
  const handleSubMetadataChange = (index, field, value) => {
    const updatedSubMetadata = [...subMetadata];
    // @ts-ignore
    updatedSubMetadata[index][field] = value;
    setSubMetadata(updatedSubMetadata);
  };
  // @ts-ignore
  const handleRemoveSubMetadata = (index) => {
    const updatedSubMetadata = [...subMetadata];
    updatedSubMetadata.splice(index, 1);
    setSubMetadata(updatedSubMetadata);
  };

  /**
   * Type select markup
   */
  // Remove select option
  const removeSelectOption = useCallback(
    (value: string) => () => {
      setValues((previousOptions) => previousOptions.filter((previousOption) => previousOption.value !== value));

      // If resultModifierType is pricing_table, update resultModifierValue
      if (resultModifierType === 'pricing_table') {
        const tmp = values
          .filter((option) => option.value !== value)
          .map((option) => option.value)
          .join(',');
        setResultModifierValue(tmp);
      }
    },
    [values],
  );

  /** Select Option Markup */
  const selectionTagMarkup = values.map((option, index) => (
    <Tag key={`${option.label}-${index}`} onRemove={removeSelectOption(option.value)}>
      <strong>Etichetta: </strong>
      {option.label} - <strong>Valore: </strong>
      {option.value}
    </Tag>
  ));

  /** Add shared notification mail when button clicked based on newTag state */
  const addSelectOption = () => {
    let flagError = false;
    if (!newSelectOption.label || !newSelectOption.value) {
      setSelectOptionError({
        label: !newSelectOption.label,
        value: !newSelectOption.value,
      });
      flagError = true;
    }
    if (flagError) return;

    newSelectOption.value !== '' &&
      newSelectOption.label !== '' &&
      !values.includes({ label: newSelectOption.label, value: newSelectOption.value }) &&
      setValues([...values, newSelectOption]);
    setNewSelectOption({
      label: '',
      value: '',
    });

    // If resultModifierType is pricing_table, update resultModifierValue
    if (resultModifierType === 'pricing_table') {
      const tmp = [...values, newSelectOption].map((option) => option.value).join(',');
      setResultModifierValue(tmp);
    }
  };

  const handleNewSelectOptionLabelOnChange = useCallback(
    (value: any) => {
      if (selectOptionError.label) {
        setSelectOptionError({ ...selectOptionError, label: false });
      }
      setNewSelectOption((previousOption) => ({ ...previousOption, label: value }));
    },
    [selectOptionError],
  );

  const handleNewSelectOptionValueOnChange = useCallback(
    (value: any) => {
      if (selectOptionError.value) {
        setSelectOptionError({ ...selectOptionError, value: false });
      }
      setNewSelectOption((previousOption) => ({ ...previousOption, value: value }));
    },
    [selectOptionError],
  );

  const typeSelectMarkup = (
    <LegacyCard.Section>
      <FormLayout>
        <LegacyStack spacing="tight">{selectionTagMarkup}</LegacyStack>
        <TextField
          label="Opzione"
          value={newSelectOption.value}
          onChange={handleNewSelectOptionValueOnChange}
          autoComplete="off"
          error={selectOptionError.value && 'Il campo è obbligatorio'}
          placeholder='Valore es. "IT"'
          connectedLeft={
            <TextField
              label="Label"
              labelHidden
              value={newSelectOption.label}
              onChange={handleNewSelectOptionLabelOnChange}
              placeholder="Etichetta es. 'Italia'"
              autoComplete="off"
              error={selectOptionError.label && 'Il campo è obbligatorio'}
            />
          }
          connectedRight={<Button onClick={() => addSelectOption()} icon={PagePlusMajor as any} />}
        />
      </FormLayout>
    </LegacyCard.Section>
  );

  /** Metadata */
  const removeMetadataSelectOption = useCallback(
    (metadataIndex: number, optionValue: string) => () => {
      setSubMetadata((prevSubMetadata) => {
        // Create a shallow copy of the subMetadata array
        const updated = [...prevSubMetadata];
        // Clone the specific nested field so that we can update it
        const currentMetadata = { ...updated[metadataIndex] };

        // Get the current list of options (or an empty array if undefined)
        const currentValues = (currentMetadata.values as { label: string; value: string }[]) || [];
        // Filter out the option to remove
        const newValues = currentValues.filter((option) => option.value !== optionValue);

        // Update the nested field's values
        currentMetadata.values = newValues;
        // If the modifier type is "pricing_table", update the modifier value accordingly
        if (resultModifierType === 'pricing_table') {
          currentMetadata.result_modifier_value = newValues.map((option) => option.value).join(',');
        }

        // Replace the nested field in the array with the updated field
        updated[metadataIndex] = currentMetadata;
        return updated;
      });
    },
    [resultModifierType],
  );

  /** Select Option Markup */
  const metadataSelectionTagMarkup = (metadataIndex: number, values: { label: string; value: string }[]) => {
    if (!values) return null;
    return values.map((option, index) => (
      <Tag key={`${option.label}-${index}`} onRemove={removeMetadataSelectOption(metadataIndex, option.value)}>
        <strong>Etichetta: </strong>
        {option.label} - <strong>Valore: </strong>
        {option.value}
      </Tag>
    ));
  };

  /** Add shared notification mail when button clicked based on newTag state */
  const addMetadataSelectOption = (metadataIndex: number) => {
    if (!newMetadataSelectOption.label || !newMetadataSelectOption.value) {
      setSelectOptionError({
        label: !newMetadataSelectOption.label,
        value: !newMetadataSelectOption.value,
      });
      return;
    }

    // Update the subMetadata at the given index.
    setSubMetadata((prevSubMetadata) => {
      // Make a shallow copy of the sub-metadata array.
      const updated = [...prevSubMetadata];
      // Copy the metadata field that we want to update.
      const currentField = { ...updated[metadataIndex] };

      // Ensure that currentField.values is an array.
      const currentValues: { label: string; value: string }[] = Array.isArray(currentField.values)
        ? (currentField.values as { label: string; value: string }[])
        : [];

      // Optionally check if the option already exists before pushing.
      if (!currentValues.find((opt) => opt.label === newMetadataSelectOption.label && opt.value === newMetadataSelectOption.value)) {
        currentValues.push({ ...newMetadataSelectOption });
      }
      // Update the field’s values.
      currentField.values = currentValues;
      // Put the updated field back into the array.
      updated[metadataIndex] = currentField;
      return updated;
    });

    // Clear the input state.
    setNewMetadataSelectOption({ label: '', value: '' });

    // If resultModifierType is pricing_table, update resultModifierValue
    // if (resultModifierType === 'pricing_table') {
    //   const tmp = [...values, newMetadataSelectOption].map((option) => option.value).join(',');
    //   setResultModifierValue(tmp);
    // }
  };

  const handleNewMetadataSelectOptionLabelOnChange = useCallback(
    (value: string, index: number) => {
      if (selectOptionError.label) {
        setSelectOptionError((prev) => ({ ...prev, label: false }));
      }
      // Update the temporary state for the new option label.
      setNewMetadataSelectOption((prevOption) => ({ ...prevOption, label: value }));
    },
    [selectOptionError],
  );

  const handleNewMetadataSelectOptionValueOnChange = useCallback(
    (value: string, index: number) => {
      if (selectOptionError.value) {
        setSelectOptionError((prev) => ({ ...prev, value: false }));
      }
      // Update the temporary state for the new option value.
      setNewMetadataSelectOption((prevOption) => ({ ...prevOption, value }));
    },
    [selectOptionError],
  );

  const metadataTypeSelectMarkup = (index: number, metadata: QuotationField) => {
    return (
      <LegacyCard.Section>
        <FormLayout>
          <LegacyStack spacing="tight">
            {metadataSelectionTagMarkup(subMetadata.indexOf(metadata), metadata.values as { label: string; value: string }[])}
          </LegacyStack>
          <TextField
            label="Opzione"
            value={newMetadataSelectOption.value}
            onChange={(val: string) => handleNewMetadataSelectOptionValueOnChange(val, subMetadata.indexOf(metadata))}
            autoComplete="off"
            error={selectOptionError.value && 'Il campo è obbligatorio'}
            placeholder='Valore es. "IT"'
            connectedLeft={
              <TextField
                label="Label"
                labelHidden
                value={newMetadataSelectOption.label}
                onChange={(val: string) => handleNewMetadataSelectOptionLabelOnChange(val, subMetadata.indexOf(metadata))}
                placeholder="Etichetta es. 'Italia'"
                autoComplete="off"
                error={selectOptionError.label && 'Il campo è obbligatorio'}
              />
            }
            connectedRight={<Button onClick={() => addMetadataSelectOption(subMetadata.indexOf(metadata))} icon={PagePlusMajor as any} />}
          />
        </FormLayout>
      </LegacyCard.Section>
    );
  };

  /**
   * Edit mode markup
   */
  const arrayTypeEditMarkup = (
    <LegacyStack.Item fill>
      <FormLayout>
        <FormLayout.Group>
          <TextField label="Nome" value={name} onChange={onNameChange} error={emptyFields.name && 'Il campo è obbligatorio'} autoComplete="off" />
          <Select label="Tipo" options={props.options} onChange={onSelectChange} value={type} />
        </FormLayout.Group>
        {/* Select markup */}
        {type === 'select' && typeSelectMarkup}

        {resultModifierType === 'pricing_table' && (
          <TextField
            label="Valori"
            value={resultModifierValue}
            onChange={onResultModifierValueChange}
            autoComplete="off"
            error={emptyFields.resultModifierValue && 'Il campo è obbligatorio'}
          />
        )}
        <FormLayout.Group>
          <TextField label="Nome field api" value={api_field_name} onChange={onApiFieldNameChange} autoComplete="off" />
        </FormLayout.Group>

        {/* Sub-metadata section */}
        <div>
          <LegacyStack vertical spacing="loose">
            {subMetadata.map((metadata, index) => {
              return (
                <div key={index}>
                  <Card>
                    <FormLayout.Group>
                      <TextField
                        label="Nome"
                        // @ts-ignore
                        value={metadata.name}
                        onChange={(value) => handleSubMetadataChange(index, 'name', value)}
                        autoComplete="off"
                      />
                      <TextField
                        label="Nome API field"
                        // @ts-ignore
                        value={metadata.api_field_name}
                        onChange={(value) => handleSubMetadataChange(index, 'api_field_name', value)}
                        autoComplete="off"
                      />
                    </FormLayout.Group>

                    <FormLayout.Group>
                      <Select
                        label="Tipo"
                        options={props.options.filter((option) => option.value !== 'array')}
                        onChange={(value) => handleSubMetadataChange(index, 'type', value)}
                        // @ts-ignore
                        value={metadata.type}
                      />
                    </FormLayout.Group>
                    {metadata.type === 'select' && metadataTypeSelectMarkup(index, metadata)}

                    <FormLayout.Group>
                      <Select
                        label="Tipologia di modificatore"
                        options={resultModifierTypeOptions}
                        value={metadata.result_modifier_type ?? 'none'}
                        onChange={(value) => handleSubMetadataChange(index, 'result_modifier_type', value)}
                      />
                      {(metadata.result_modifier_type === 'percentage' || metadata.result_modifier_type === 'fixed_amount') && (
                        <TextField
                          label="Valore modificatore"
                          value={metadata.result_modifier_value}
                          onChange={(value) => handleSubMetadataChange(index, 'result_modifier_value', value)}
                          autoComplete="off"
                          error={emptyFields.resultModifierValue && 'Il campo è obbligatorio'}
                        />
                      )}
                      {metadata.result_modifier_type === 'pricing_table' && (
                        <TextField
                          label="Valori"
                          value={metadata.result_modifier_value}
                          onChange={(value) => handleSubMetadataChange(index, 'result_modifier_value', value)}
                          autoComplete="off"
                          error={emptyFields.resultModifierValue && 'Il campo è obbligatorio'}
                        />
                      )}
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <Button destructive onClick={() => handleRemoveSubMetadata(index)}>
                        Rimuovi
                      </Button>
                    </FormLayout.Group>
                  </Card>
                </div>
              );
            })}
          </LegacyStack>
        </div>
        <Button onClick={handleAddSubMetadata}>Aggiungi campo metadata</Button>
        <Button onClick={handleSave}>Fatto</Button>
      </FormLayout>
    </LegacyStack.Item>
  );

  const editModeMarkup = (
    <LegacyStack.Item fill>
      <FormLayout>
        <FormLayout.Group>
          <TextField label="Nome" value={name} onChange={onNameChange} error={emptyFields.name && 'Il campo è obbligatorio'} autoComplete="off" />
          <Select label="Tipo" options={props.options} onChange={onSelectChange} value={type} />
        </FormLayout.Group>
        {/* Select markup */}
        {type === 'select' && typeSelectMarkup}

        <FormLayout.Group>
          <Select label="Tipologia di modificatore" options={resultModifierTypeOptions} value={resultModifierType} onChange={onResultModifierTypeChange} />
          {(resultModifierType === 'percentage' || resultModifierType === 'fixed_amount') && (
            <TextField
              label="Valore modificatore"
              value={resultModifierValue}
              onChange={onResultModifierValueChange}
              autoComplete="off"
              error={emptyFields.resultModifierValue && 'Il campo è obbligatorio'}
            />
          )}
        </FormLayout.Group>
        {resultModifierType === 'pricing_table' && (
          <>
            <TextField
              label="Valori"
              value={resultModifierValue}
              onChange={onResultModifierValueChange}
              autoComplete="off"
              error={emptyFields.resultModifierValue && 'Il campo è obbligatorio'}
            />
          </>
        )}
        <FormLayout.Group>
          <TextField label="Nome field api" value={api_field_name} onChange={onApiFieldNameChange} autoComplete="off" />
        </FormLayout.Group>
        <Button onClick={handleSave}>Fatto</Button>
      </FormLayout>
    </LegacyStack.Item>
  );

  /**
   * Display mode markup
   */
  const displayModeMarkup = (
    <LegacyStack.Item fill>
      <Text as="span" fontWeight="semibold">
        {name}
      </Text>
      <div>
        Tipo: {props.options.find((option: any) => option.value === type)?.label}{' '}
        {type !== 'text' && type !== 'select' && type !== 'array' && type !== 'date'
          ? `— Valore: ${resultModifierValue}`
          : type === 'array' && '— Elementi: ' + subMetadata.length}
      </div>
    </LegacyStack.Item>
  );

  return (
    <LegacyCard.Section>
      <div ref={setNodeRef} style={style}>
        <LegacyStack alignment="center">
          <LegacyStack.Item>
            {/* <Icon source={DragHandleMinor} {...attributes} {...listeners} /> */}
            <Button plain icon={DragHandleMinor as any} {...attributes} {...listeners} />
          </LegacyStack.Item>
          {isEditing ? (type === 'array' ? arrayTypeEditMarkup : editModeMarkup) : displayModeMarkup}
          <LegacyStack.Item>
            {isEditing ? (
              <Button icon={DeleteMinor as any} plain onClick={onDelete} />
            ) : (
              <Button size="slim" onClick={() => setIsEditing(true)}>
                Modifica
              </Button>
            )}
          </LegacyStack.Item>
        </LegacyStack>
      </div>
    </LegacyCard.Section>
  );
}
