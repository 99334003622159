import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  Banner,
  Autocomplete,
  Text,
  LegacyStack,
  Badge,
  EmptyState,
  DatePicker,
  Modal,
  Select,
  ResourceList,
  ResourceItem,
  DropZone,
  OptionList,
  Link,
  VerticalStack,
  Avatar,
  Divider,
  DataTable,
  Spinner,
  Tooltip,
} from '@shopify/polaris';

import axios, { AxiosError } from 'axios';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../components';

import { AddNoteMajor, ClipboardMinor, DeleteMajor, NoteMinor, SearchMinor } from '@shopify/polaris-icons';

import {
  Automation,
  Customer,
  DiscountApplication,
  Order,
  OrderCommission,
  OrderFileType,
  OrderMetadata,
  Organization,
  QuotationMetadata,
  Transaction,
  Variant,
} from '../../types';
import { parseDateLabel, parseOrderFileType, parseOrderTimelineStatus, renderOrderStatusBadge } from '../../utils/Common';
import { DiscountModal } from '../Orders/DiscountModal';
import { saveAs } from 'file-saver';

import dayjs from 'dayjs';
import it from 'dayjs/locale/it';
import { useParams } from 'react-router-dom';
import { useUser } from '../../utils/PrivateRoute';

type AutocompleteProductOption = {
  label: string;
  value: string;
  productId: string;
};

type AutocompleteCustomerOption = {
  label: string;
  value: string;
};

type Label = 'type' | 'date_start' | 'date_end' | 'subtotal' | 'name';

interface Item {
  label: Label;
  value: string;
}

export function PolicyDetails() {
  const params = useParams();
  const { user } = useUser();
  const [missingUserError, setMissingUserError] = useState(false);
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [buttonSpinningEmail, setButtonSpinningEmail] = useState(false);
  const [buttonSpinningDelete, setButtonSpinningDelete] = useState(false);
  const [buttonSpinningUpload, setButtonSpinningUpload] = useState(false);
  const [buttonSpinningScan, setButtonSpinningScan] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [active, setActive] = useState(false);
  const [discountModalActive, setDiscountModalActive] = useState(false);
  const [managementPriceModalActive, setManagementPriceModalActive] = useState(false);
  const [subtotalModalActive, setSubtotalModalActive] = useState(false);
  const [uploadQuoteFileActive, setUploadQuoteFileActive] = useState(false);

  const uploadQuoteFileActiveRef = useRef(uploadQuoteFileActive);

  useEffect(() => {
    uploadQuoteFileActiveRef.current = uploadQuoteFileActive;
  }, [uploadQuoteFileActive]);

  const [orderStatusModalActive, setOrderStatusModalActive] = useState(false);
  const handleOrderStatusModalChange = useCallback(() => setOrderStatusModalActive(!orderStatusModalActive), [orderStatusModalActive]);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [saveError, setSaveError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [scanError, setScanError] = useState(false);

  const [paymentActive, setPaymentActive] = useState(false);
  const togglePaymentActive = useCallback(() => setPaymentActive((paymentActive) => !paymentActive), []);

  const [deletePaymentActive, setDeletePaymentActive] = useState(false);
  const toggleDeletePaymentActive = useCallback(() => setDeletePaymentActive((deletePaymentActive) => !deletePaymentActive), []);

  const [paymentModalActive, setPaymentModalActive] = useState(false);
  const handlePaymentModalChange = useCallback(() => setPaymentModalActive(!paymentModalActive), [paymentModalActive]);

  const [invoiceSentActive, setInvoiceSentActive] = useState(false);
  const toggleInvoiceSentActive = useCallback(() => setInvoiceSentActive((invoiceSentActive) => !invoiceSentActive), []);

  const [fileUploadActive, setFileUploadActive] = useState(false);
  const toggleFileUploadActive = useCallback(() => setFileUploadActive((fileUploadActive) => !fileUploadActive), []);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Order states
   */
  const [order, setOrder] = useState<Order | null>(null);

  // Order status options
  const orderStatusOptions = [
    { label: 'Preventivo', value: 'quote' },
    { label: 'Preventivo accettato', value: 'quote_accepted' },
    { label: 'Preventivo rifiutato', value: 'quote_rejected' },
    { label: 'Pagato', value: 'paid' },
    { label: 'Scaduto', value: 'expired' },
    { label: 'Emesso', value: 'emitted' },
  ];
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<string>('quote');

  const [deselectedProductOptions, setDeselectedProductOptions] = useState<AutocompleteProductOption[]>([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState<string[]>([]);
  const [productInputValue, setProductInputValue] = useState('');
  const [productOptions, setProductOptions] = useState(deselectedProductOptions);
  const [productLoading, setProductLoading] = useState(false);
  const [variants, setVariants] = useState<Variant[]>([]);
  const [lineItemsVariant, setLineItemsVariant] = useState<Variant[]>([]);
  const [lineItems, setLineItems] = useState<string[]>([]);

  const [manualSubtotalPrice, setManualSubtotalPrice] = useState(false);
  const [subtotalPrice, setSubtotalPrice] = useState('0,00');
  const [subtotalQuotationPrice, setSubtotalQuotationPrice] = useState<number>(0);
  const [discountPrice, setDiscountPrice] = useState('0,00');
  const [managementPrice, setManagementPrice] = useState('');
  const [totalPrice, setTotalPrice] = useState('0,00');

  /** Temporary values */
  const [tmpSubtotalPrice, setTmpSubtotalPrice] = useState('0,00');
  const [tmpTotalPrice, setTmpTotalPrice] = useState('0,00');

  /** Harp pricing */
  const [generaliPrice, setGeneraliPrice] = useState('0,00');
  const [sbPrice, setSbPrice] = useState('0,00');

  const [deselectedCustomerOptions, setDeselectedCustomerOptions] = useState<AutocompleteCustomerOption[]>([]);
  const [selectedCustomerOptions, setSelectedCustomerOptions] = useState<string[]>([]);
  const [customerInputValue, setCustomerInputValue] = useState('');
  const [customerOptions, setCustomerOptions] = useState(deselectedCustomerOptions);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);

  // Dates (add one year to expired dates)
  const [monthCreated, setMonthCreated] = useState(new Date().getMonth());
  const [yearCreated, setYearCreated] = useState(new Date().getFullYear());
  const [dateCreatedSelection, setDateCreatedSelection] = useState(false);
  const [selectedDatesCreated, setSelectedDatesCreated] = useState({ start: new Date(), end: new Date() });

  const [monthExpired, setMonthExpired] = useState(new Date().getMonth());
  const [yearExpired, setYearExpired] = useState(new Date().getFullYear() + 1);
  const [dateExpiredSelection, setDateExpiredSelection] = useState(false);
  const [selectedDatesExpired, setSelectedDatesExpired] = useState({
    start: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
    end: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
  });

  const [automation, setAutomation] = useState<Automation | null>(null);
  const [automationBotSuccess, setAutomationBotSuccess] = useState(false);
  const [hasAutomationStarted, setHasAutomationStarted] = useState(false);
  const [automationBotError, setAutomationBotError] = useState('');

  // Copy
  const [toastCopyActive, setToastCopyActive] = useState(false);
  const toggleToastCopyActive = useCallback(() => setToastCopyActive((toastCopyActive) => !toastCopyActive), []);

  const toastCopyMarkup = toastCopyActive ? <Toast content="Il dato è stato copiato" onDismiss={toggleToastCopyActive} /> : null;

  // Notes
  const [notes, setNotes] = useState('');

  // Metadata
  const [metadata, setMetadata] = useState<OrderMetadata[] | []>([]);

  // Commission
  const [commissions, setCommissions] = useState<OrderCommission[]>([]);

  // Discounts
  const [discount, setDiscount] = useState<DiscountApplication | null>(null);
  const [newDiscount, setNewDiscount] = useState<boolean>(true);

  // Payments
  const [amount, setAmount] = useState('');
  const [monthPayment, setMonthPayment] = useState(new Date().getMonth());
  const [yearPayment, setYearPayment] = useState(new Date().getFullYear());
  const [datePaymentSelection, setDatePaymentSelection] = useState(false);
  const [selectedDatesPayment, setSelectedDatesPayment] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [selectedPaymentType, setSelectedPaymentType] = useState<'cash' | 'transfer' | 'card' | 'other'>('transfer');

  const paymentOptions = [
    { label: 'Bonifico', value: 'transfer', key: 1 },
    { label: 'Contanti', value: 'cash', key: 2 },
    { label: 'Bancomat/Carta', value: 'card', key: 3 },
    { label: 'Altro', value: 'other', key: 4 },
  ];

  const [payments, setPayments] = useState<Transaction[] | []>([]);

  /**
   * File Upload - Documenti
   */
  const [files, setFiles] = useState<string[]>([]);
  const [fileID, setFileID] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [txtFileUploadValue, setTxtFileUploadValue] = useState('');
  const [tmpFileUploadValue, setTmpFileUploadValue] = useState<any>();
  const [fileScanSuccessful, setFileScanSuccessful] = useState<boolean>(false);
  const [selectedFileUpload, setSelectedFileUpload] = useState<string[]>([]);
  const [optionsFileUpload, setOptionsFileUpload] = useState<{ label: string; value: string }[]>([]);
  const [activeFileUpload, setActiveFileUpload] = useState(false);
  const [isAdderFileUpload, setIsAdderFileUpload] = useState(false);
  const [selectedType, setSelectedType] = useState<'quote' | 'policy' | 'renewal'>(OrderFileType.QUOTE);

  const tmpFileUploadValueRef = useRef<any>();

  useEffect(() => {
    tmpFileUploadValueRef.current = tmpFileUploadValue;
  }, [tmpFileUploadValue]);

  const typeOptions = [
    { label: 'Preventivo', value: OrderFileType.QUOTE },
    { label: 'Polizza', value: OrderFileType.POLICY },
    { label: 'Rinnovo', value: OrderFileType.RENEWAL },
  ];

  // Empty fields
  const [emptyFields, setEmptyFields] = useState({
    lineItems: false,
    customer: false,
  });

  /**
   * Copy handlers
   */
  // Copy e-mail
  const handleCopyEmail = useCallback(() => {
    navigator.clipboard.writeText(order?.user?.email ?? '');
    toggleToastCopyActive();
  }, [order]);

  // Copy phone
  const handleCopyPhone = useCallback(() => {
    navigator.clipboard.writeText(order?.user?.phone ?? '');
    toggleToastCopyActive();
  }, [order]);

  // Copy address
  const handleCopyAddress = useCallback(() => {
    navigator.clipboard.writeText(`${customer?.address?.line ?? ''} ${customer?.address?.city ?? ''} ${customer?.address?.zip ?? ''}`);
    toggleToastCopyActive();
  }, [customer]);

  // Copy CF
  const handleCopyCF = useCallback(() => {
    navigator.clipboard.writeText(customer?.fiscal_code ?? '');
    toggleToastCopyActive();
  }, [customer]);

  // Copy PIVA
  const handleCopyVAT = useCallback(() => {
    navigator.clipboard.writeText(order?.user?.customer?.vat ?? '');
    toggleToastCopyActive();
  }, [order]);

  // Copy ATECO
  const handleCopyATECO = useCallback(() => {
    navigator.clipboard.writeText(order?.user?.customer?.ateco ?? '');
    toggleToastCopyActive();
  }, [order]);

  /**
   * Data fetching:
   * - fetch order
   * - fetch products
   * - fetch customers
   */
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/policies/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const order: Order = data.data;
          setOrder(order);
          setLineItems(order.line_items.map((item: any) => item._id));
          setCustomer(order.user.customer);
          setNotes(order.notes ? order.notes : '');
          setDiscount(order.discount_application ? order.discount_application : null);
          setNewDiscount(order.discount_application ? false : true);

          // Set prices
          setSubtotalPrice(order.subtotal_price.toFixed(2).replace('.', ','));
          const tmp = order.metadata?.reduce((acc: number, meta: OrderMetadata) => {
            const index = meta.fields.findIndex((field: any) => field.label === 'quotation');
            if (index !== -1) {
              return acc + Number(meta.fields[index].value);
            }
            return acc;
          }, 0);
          setSubtotalQuotationPrice(tmp || 0);
          setManagementPrice(order.management_price ? order.management_price.toFixed(2).replace('.', ',') : '');
          setTotalPrice(order.total_price.toFixed(2).replace('.', ','));

          // Set autocomplete values
          setSelectedCustomerOptions([order.user.name]);
          setCustomerInputValue(order.user.name);

          // Set dates
          setSelectedDatesCreated({
            start: order.date_start ? new Date(order.date_start) : new Date(),
            end: order.date_start ? new Date(order.date_start) : new Date(),
          });
          setSelectedDatesExpired({
            start: order.date_end ? new Date(order.date_end) : new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
            end: order.date_end ? new Date(order.date_end) : new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
          });
          if (order.status === 'paid' && (!order.date_start || !order.date_end)) {
            setIsDirty(true);
          }

          // Set payments
          setPayments(order.transactions);

          // Set metadata
          setMetadata(order.metadata ? order.metadata : []);

          // Set commissions
          setCommissions(order.commissions ? order.commissions : []);

          // Set line items
          setLineItemsVariant(order.line_items);

          // Set order already registered to insurance portal
          if (order.order_registered_on_portal) {
            setAutomationBotSuccess(order.order_registered_on_portal);
          }

          // If the variant set is Arpa Sicura, then compute the harp pricing
          if (order.line_items.find((x) => x.name === 'Arpa Sicura') && order.metadata && order.metadata.length > 0) {
            // Get Valore arpa in fattura from the metadata
            const valoreArpa = order.metadata[0].fields.find((x) => x.label === 'Valore arpa in fattura')?.value;

            if (!valoreArpa) {
              setGeneraliPrice('0,00');
              setSbPrice('0,00');
            }

            const tasso = 0.004;
            const invoice_a = (valoreArpa as number) * tasso;
            const invoice_b = invoice_a + invoice_a * 0.1;
            const invoice_generali = invoice_b + invoice_b * 0.2125;
            const invoice_sb = order.subtotal_price - invoice_generali;
            setGeneraliPrice(invoice_generali.toFixed(2).replace('.', ','));
            setSbPrice(invoice_sb.toFixed(2).replace('.', ','));
          }

          // Files
          const fileArray = [];
          const fileNamesArray = [];
          const fileIdsArray = [];
          const fileOptions = [];
          const fileTypes = [];

          if (order.files) {
            for (const item of order.files) {
              fileArray.push('Document');
              fileNamesArray.push(item.title);
              fileIdsArray.push(item._id);
              let fileName = '';
              if (item.title.includes('__')) {
                fileName = item.title.split('__')[1];
              } else {
                fileName = item.title;
              }
              fileOptions.push({
                label: `${fileName} - ${parseOrderFileType(item.file_type)}`,
                value: item.key,
              });
              fileTypes.push(item.file_type);
            }
          }

          setFiles(fileArray);
          setFileNames(fileNamesArray);
          setFileID(fileIdsArray.filter((id) => id !== undefined) as string[]);
          setOptionsFileUpload(fileOptions);
        } else {
          setError(true);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchProducts = async () => {
      try {
        setProductLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/products', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const variantsTmp: any[] = [];
          const productOptions = [];
          for (const product of data.data) {
            for (const variant of product.variants) {
              productOptions.push({
                value: variant._id,
                label: `${product.name} - ${variant.name}`,
                productId: product._id,
              });
              variantsTmp.push(variant);
            }
          }

          setDeselectedProductOptions(productOptions);
          setProductOptions(productOptions);

          setVariants(variantsTmp);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      } finally {
        setProductLoading(false);
      }
    };
    const fetchCustomers = async () => {
      try {
        setCustomerLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/customers', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const customerOptions = [];
          for (const customer of data.data) {
            customerOptions.push({
              value: customer._id,
              label: `${customer.name}`,
              customer: customer,
            });
          }

          setCustomerOptions(customerOptions);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setCustomerLoading(false);
      }
    };

    fetchOrder();
    fetchProducts();
    fetchCustomers();
  }, [update]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);

      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/orders/${params.id}`,
        {
          notes: notes,
          status: order?.status,
          subtotal: Number(subtotalPrice.replace('.', '').replace(',', '.')),
          total: Number(totalPrice.replace('.', '').replace(',', '.')),
          discount_application: discount,
          date_start: selectedDatesCreated.start,
          date_end: selectedDatesExpired.end,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setIsDirty(false);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [lineItems, order, subtotalPrice, totalPrice, notes, discount, selectedDatesCreated, selectedDatesExpired]);

  /**
   * Handle order status change
   */
  const handleOrderStatusChange = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/orders/${params.id}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        body: JSON.stringify({
          status: selectedOrderStatus,
        }),
      });
      const data = await response.json();

      if (data.status === 'success') {
        setUpdate(!update);
        setActive(true);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
      handleOrderStatusModalChange();
    }
  }, [update, selectedOrderStatus]);

  /**
   * Add payment
   */
  const handleAddPayment = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/orders/${params.id}/payment`,
        {
          amount: amount,
          type: selectedPaymentType,
          date_paid: selectedDatesPayment.start,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setUpdate(!update);
        setPaymentActive(true);
      } else if (data.status === 'amount_higher') {
        setAmountError(true);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError.response);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
      setPaymentModalActive(false);
    }
  }, [amount, params.id, payments, selectedDatesPayment.start, selectedPaymentType, update]);

  /**
   * Delete payment
   */
  const handleDeletePayment = useCallback(
    async (transactionId: string) => {
      try {
        setButtonSpinningDelete(true);
        const response = await fetch(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/orders/${params.id}/payment/${transactionId}`,
          {
            method: 'DELETE',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
            body: JSON.stringify({}),
          },
        );
        const data = await response.json();

        if (data.status === 'success') {
          setDeletePaymentActive(true);
          setUpdate(!update);
        } else {
          setSaveError(true);
        }
      } catch (error) {
        console.log(error);
        setSaveError(true);
      } finally {
        setButtonSpinningDelete(false);
      }
    },
    [params.id, update],
  );

  /**
   * Handle print order
   */
  const handlePrintOrder = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/orders/${params.id}/print`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      const order_type = order?.status === 'quote' ? 'quote' : order?.status === 'paid' || order?.status === 'emitted' ? 'invoice' : '';
      const file_type = order_type === 'quote' ? 'preventivo' : order_type === 'invoice' ? 'ricevuta' : '';
      saveAs(
        data,
        `${file_type}_${order?.user.lastname ? order?.user.name + order.user.lastname : order?.user.name}_${dayjs(order?.date_created)
          .locale(it)
          .format('DD/MM/YYYY')}.pdf`,
      );
    } catch (error) {
      // setSaveError(true);
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        setSaveError(true);
      }
    } finally {
      setButtonSpinning(false);
    }
  }, [params.id, order]);

  /**
   * Handle send order invoice
   */
  const handleSendOrderInvoice = useCallback(async () => {
    try {
      setButtonSpinningEmail(true);
      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/orders/${params.id}/send-invoice`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setInvoiceSentActive(true);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        setSaveError(true);
      }
    } finally {
      setButtonSpinningEmail(false);
    }
  }, [params.id]);

  /**
   * Order Handlers
   */
  const handleNotesChange = useCallback((e: any) => {
    setNotes(e);
    setIsDirty(true);
  }, []);

  const handleSelectOrderStatusChange = useCallback((e: any) => {
    setSelectedOrderStatus(e);
  }, []);

  // Handle discount modal
  const handleDiscountModal = useCallback(() => {
    // If discount value is defined, then we are editing the discount
    if (discount?.value !== undefined && discount?.value > 0) {
      setNewDiscount(false);
    } else {
      // Set default values
      setDiscount({
        value: 0,
        type: 'manual',
        value_type: 'fixed_amount',
        description: '',
      });

      setNewDiscount(true);
    }

    setDiscountModalActive(true);
  }, [discount]);

  /**
   * Start date handlers
   */
  const handleMonthCreatedChange = useCallback(
    (month: number, year: number) => {
      setMonthCreated(month);
      setYearCreated(year);
    },
    [{ monthCreated, yearCreated }],
  );
  const handleDateCreatedSelection = useCallback(() => {
    setDateCreatedSelection(!dateCreatedSelection);
  }, [dateCreatedSelection]);
  const handleSelectedDatesCreated = useCallback(
    (e: any) => {
      setSelectedDatesCreated(e);

      // Set expired date to 1 year after created date
      setSelectedDatesExpired({
        start: new Date(e.start.getFullYear() + 1, e.start.getMonth(), e.start.getDate()),
        end: new Date(e.start.getFullYear() + 1, e.start.getMonth(), e.start.getDate()),
      });

      setIsDirty(true);
      if (dateCreatedSelection) setDateCreatedSelection(false);
    },
    [dateCreatedSelection],
  );

  /**
   * Expired Handlers
   */
  const handleMonthExpiredChange = useCallback(
    (month: number, year: number) => {
      setMonthExpired(month);
      setYearExpired(year);
    },
    [{ monthExpired, yearExpired }],
  );
  const handleDateExpiredSelection = useCallback(() => {
    setDateExpiredSelection(!dateExpiredSelection);
  }, [dateExpiredSelection]);
  const handleSelectedDatesExpired = useCallback(
    (e: any) => {
      setSelectedDatesExpired(e);
      setIsDirty(true);
      if (dateExpiredSelection) setDateExpiredSelection(false);
    },
    [dateExpiredSelection],
  );

  const scanErrorMarkup = scanError && (
    <>
      <br></br>
      <Banner title="Si è verificato un errore nello scan del file" status="critical" onDismiss={() => setScanError(false)}>
        <p>Si è pregati di inserire i dati manualmente</p>
      </Banner>
    </>
  );

  const loadingBarFileScanMarkup = buttonSpinningScan && (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
      <br></br>
      <p>Stiamo analizzando il file, potrebbe volerci fino a 1 minuto.</p>
      <br></br>
      <Spinner accessibilityLabel="Scanning file" size="large" />
    </div>
  );

  // Scan the file and update subtotal price if found
  const handleScanFile = useCallback(
    async (file: File) => {
      try {
        const formData = new FormData();
        if (file) {
          formData.append('document', file);
        }

        setButtonSpinningScan(true);
        const response = await axios.post(`${process.env.REACT_APP_API_URL ?? '/api'}/quotes/scan/minified`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success' && data.data.scan_results && data.data.scan_results.length !== 0) {
          // Set selected type based on the item containing value type
          const items: Item[] = data.data.scan_results;
          const result = items.reduce<Record<Label, string>>((acc, item) => {
            acc[item.label] = item.value;
            return acc;
          }, {} as Record<Label, string>);

          const tmpFileName = tmpFileUploadValueRef.current?.name.split('__')[1];

          // Set the values
          if (uploadQuoteFileActiveRef.current && tmpFileName === file.name) {
            const subtotal = result.subtotal;
            setSubtotalPrice(subtotal);
            setTmpSubtotalPrice(subtotal);

            // Save policy dates
            if (selectedType === 'quote') {
              const date_start_separator = result.date_start.includes('-') ? '-' : '/';
              const date_end_separator = result.date_end.includes('-') ? '-' : '/';
              const [date_start_d, date_start_m, date_start_y] = result.date_start.split(date_start_separator);
              const [date_end_d, date_end_m, date_end_y] = result.date_end.split(date_end_separator);

              const date_start = result.date_start ? new Date(`${date_start_y}-${date_start_m}-${date_start_d}`) : new Date();
              const date_end = result.date_end
                ? new Date(`${date_end_y}-${date_end_m}-${date_end_d}`)
                : new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate());

              setSelectedDatesCreated({
                start: date_start,
                end: date_start,
              });

              setSelectedDatesExpired({
                start: date_end,
                end: date_end,
              });
            }

            setIsDirty(true);
            setFileScanSuccessful(true);
          }
        } else {
          const tmpFileName = tmpFileUploadValueRef.current?.name.split('__')[1];

          if (uploadQuoteFileActiveRef.current && tmpFileName === file.name) {
            setScanError(true);
          }
        }
      } catch (error) {
        console.log(error);
        const tmpFileName = tmpFileUploadValueRef.current?.name.split('__')[1];

        if (uploadQuoteFileActiveRef.current && tmpFileName === file.name) {
          setScanError(true);
        }
      } finally {
        const tmpFileName = tmpFileUploadValueRef.current?.name.split('__')[1];

        if (uploadQuoteFileActiveRef.current && tmpFileName === file.name) {
          setButtonSpinningScan(false);
        }
      }
    },
    [update],
  );

  /**
   * File Uploading Handler
   */
  const toggleModalFileUpload = useCallback(() => setActiveFileUpload((activeFileUpload: boolean) => !activeFileUpload), []);
  const toggleIsAdderFileUpload = useCallback((e: boolean) => {
    setIsAdderFileUpload((isAdderFileUpload) => {
      isAdderFileUpload = e;
      return isAdderFileUpload;
    });
  }, []);

  const handleFileUploadAdd = useCallback(
    async (e: any, fn: string, type: 'policy' | 'quote' | 'renewal', date_start?: Date, date_end?: Date, subtotal?: string, total?: string) => {
      if (e === '' || e === undefined || fn === '' || fn === undefined || type === undefined) return;

      setFiles((files) => {
        const newFiles = files;
        //@ts-ignore
        newFiles.push(e);
        return newFiles;
      });

      setFileNames((fileNames) => {
        const newFileNames = fileNames;
        //@ts-ignore
        newFileNames.push(fn);
        return newFileNames;
      });

      setOptionsFileUpload((optionsFileUpload) => {
        const newOptions = optionsFileUpload;
        //@ts-ignore
        newOptions.push({ label: `${fn.split('__')[1]} - ${type}`, value: fn });
        return newOptions;
      });

      // Update subtotal and total in variables
      const tmpSubtotal = subtotal || null;
      const tmpTotal = total || null;

      if (tmpSubtotal && tmpTotal) {
        setSubtotalPrice(tmpSubtotal);
        setTmpSubtotalPrice(tmpSubtotal);
        setTotalPrice(tmpTotal);
        setTmpTotalPrice(tmpTotal);
      }

      const addFile = async () => {
        try {
          setButtonSpinningUpload(true);

          const fd = new FormData();
          fd.append('document', e);
          fd.append('file_type', type);

          if (date_start) {
            fd.append('date_start', date_start.toISOString());
          }

          if (date_end) {
            fd.append('date_end', date_end.toISOString());
          }

          const response = await axios.post(`${process.env.REACT_APP_API_URL ?? `/api`}/admin/orders/${params.id}/upload`, fd, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          });
          const data = response.data;
          if (data.status === 'success') {
            setFileUploadActive(true);

            if (uploadQuoteFileActive) {
              handleUploadQuoteFileModal();
              handleEditSubtotalModal();
              handleSave().then(() => setUpdate(!update));
            } else {
              setUpdate(!update);
            }
          }
        } catch (error) {
          const axiosError = error as AxiosError;
          if (axiosError.response) {
            console.log(axiosError.response.data);
          }
          setError(true);
        } finally {
          setButtonSpinningUpload(false);
          // Close modal if upload quote is not active
          !uploadQuoteFileActive && toggleModalFileUpload();
        }
      };
      await addFile();
    },
    [params.id, typeOptions, update, toggleModalFileUpload],
  );

  const handleConfirmFileUpload = useCallback(
    (subtotal?: string, total?: string) => {
      if (isAdderFileUpload) {
        handleFileUploadAdd(
          tmpFileUploadValue,
          txtFileUploadValue,
          selectedType,
          selectedType === 'policy' ? selectedDatesCreated.start : undefined,
          selectedType === 'policy' ? selectedDatesExpired.start : undefined,
          subtotal,
          total,
        );
      }
      // else {
      //   handleFileUploadModify(tmpFileUploadValue, txtFileUploadValue, selectedFileUpload[0]);
      // }
    },
    [
      isAdderFileUpload,
      handleFileUploadAdd,
      tmpFileUploadValue,
      txtFileUploadValue,
      selectedType,
      selectedFileUpload,
      selectedDatesCreated,
      selectedDatesExpired,
    ],
  );

  const handleTmpFileUploadValue = useCallback((e: any) => {
    setTmpFileUploadValue(e);
  }, []);

  const handleAddFileUpload = useCallback(
    (_dropFiles: any, acceptedFiles: any, _rejectedFiles: any) => {
      toggleModalFileUpload();
      toggleIsAdderFileUpload(true);
      const blob = acceptedFiles[0];
      const file = new File([blob], params.id + '__' + acceptedFiles[0].name);
      setTxtFileUploadValue(file.name);
      // @ts-ignore
      setTmpFileUploadValue((tmpFileUploadValue) => file);
    },
    [toggleModalFileUpload, toggleIsAdderFileUpload, params.id],
  );

  const handleAddFileUploadInModal = useCallback(
    (_dropFiles: any, acceptedFiles: any, _rejectedFiles: any) => {
      setFileScanSuccessful(false);
      setScanError(false);
      handleEditSubtotalModal();
      handleUploadQuoteFileModal();
      toggleIsAdderFileUpload(true);
      const blob = acceptedFiles[0];
      const file = new File([blob], params.id + '__' + acceptedFiles[0].name);
      setTxtFileUploadValue(file.name);
      // @ts-ignore
      setTmpFileUploadValue((tmpFileUploadValue) => file);
      // Scanning the file
      handleScanFile(blob);
    },
    [toggleIsAdderFileUpload, params.id],
  );

  const handleSelectionFileUpload = useCallback((e: any) => {
    setSelectedFileUpload(e);
  }, []);

  /**
   * Delete file
   */
  const handleDelFileUpload = useCallback(
    async (fileKey: string) => {
      if (!fileKey) return;
      // Find the index to remove from optionsFileUpload (and related state arrays)
      const indexToDelete = optionsFileUpload.findIndex((op) => op.value === fileKey);
      if (indexToDelete === -1) return;
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api'}/admin/orders/${params.id}/files/${fileKey}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
      } catch (error) {
        console.log(error);
        setError(true);
      }
      // Update state arrays to remove the file
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles.splice(indexToDelete, 1);
        return newFiles;
      });
      setFileNames((prevNames) => {
        const newNames = [...prevNames];
        newNames.splice(indexToDelete, 1);
        return newNames;
      });
      setFileID((prevIDs) => {
        const newIDs = [...prevIDs];
        newIDs.splice(indexToDelete, 1);
        return newIDs;
      });
      setOptionsFileUpload((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions.splice(indexToDelete, 1);
        return newOptions;
      });
      handleSelectionFileUpload(['']);
    },
    [optionsFileUpload, params.id],
  );

  /**
   * Download single file
   */
  const handleOpenFile = useCallback(
    async (fileKey: string) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/orders/${params.id}/files/${fileKey}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const blob = await response.blob();
        saveAs(blob, fileKey);
      } catch (error) {
        console.log(error);
        setError(true);
      }
    },
    [params.id],
  );

  const fileUpload = !tmpFileUploadValue && <DropZone.FileUpload />;
  const uploadedFile = tmpFileUploadValue && (
    <LegacyStack>
      <div>
        {/*
        // @ts-ignore */}
        {tmpFileUploadValue.name.split('__')[1]}{' '}
        <Text variant="bodySm" as="p">
          {tmpFileUploadValue.size} bytes
        </Text>
      </div>
    </LegacyStack>
  );

  /**
   * Policy dates markup
   */
  const renderPolicyDatesMarkup = (active: boolean) => {
    if (!active) return;
    return (
      <LegacyCard title="Date" sectioned>
        <FormLayout>
          {/* Date start */}
          <FormLayout.Group>
            <TextField
              autoComplete="on"
              type="text"
              disabled={true}
              labelHidden={true}
              label="Data di Inizio"
              value={selectedDatesCreated.start.toLocaleDateString('it-IT', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            />
            <Button onClick={handleDateCreatedSelection}>Seleziona Data di Decorrenza Polizza</Button>
          </FormLayout.Group>
          <FormLayout.Group>
            {dateCreatedSelection && (
              <DatePicker
                month={monthCreated}
                year={yearCreated}
                onChange={handleSelectedDatesCreated}
                onMonthChange={handleMonthCreatedChange}
                selected={selectedDatesCreated}
                allowRange={false}
                weekStartsOn={1}
              />
            )}
          </FormLayout.Group>
          {/* Date end */}
          <FormLayout.Group>
            <TextField
              autoComplete="on"
              type="text"
              disabled={true}
              labelHidden={true}
              label="Data di Scadenza"
              value={selectedDatesExpired.start.toLocaleDateString('it-IT', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            />
            <Button onClick={handleDateExpiredSelection}>Seleziona Data di Scadenza Polizza</Button>
          </FormLayout.Group>
          <FormLayout.Group>
            {dateExpiredSelection && (
              <DatePicker
                month={monthExpired}
                year={yearExpired}
                onChange={handleSelectedDatesExpired}
                onMonthChange={handleMonthExpiredChange}
                selected={selectedDatesExpired}
                allowRange={false}
                weekStartsOn={1}
              />
            )}
          </FormLayout.Group>
        </FormLayout>
      </LegacyCard>
    );
  };

  /**
   * Payment date handlers
   */
  const handleMonthPaymentChange = useCallback(
    (month: number, year: number) => {
      setMonthPayment(month);
      setYearPayment(year);
    },
    [{ monthPayment, yearPayment }],
  );
  const handleDatePaymentSelection = useCallback(() => {
    setDatePaymentSelection(true);
  }, []);
  const handleSelectedDatesPayment = useCallback(
    (e: any) => {
      setSelectedDatesPayment(e);
      if (datePaymentSelection) setDatePaymentSelection(false);
    },
    [datePaymentSelection],
  );

  const handleAmountChange = useCallback((value: any) => {
    setAmount(value);
  }, []);

  const handleSelectPaymentChange = useCallback((value: any) => {
    setSelectedPaymentType(value);
  }, []);

  // Handle edit subtotal
  const handleEditSubtotalModal = useCallback(() => {
    setSubtotalModalActive(!subtotalModalActive);
  }, [subtotalModalActive]);

  // Handle upload file to quote
  const handleUploadQuoteFileModal = useCallback(() => {
    setUploadQuoteFileActive(!uploadQuoteFileActive);
  }, [uploadQuoteFileActive]);

  const handleSubtotalChange = useCallback(
    (value: any) => {
      // Check if the price has 2 or more commas and remove the last one
      if (value.split(',').length > 2) {
        value = value.replace(/,(?=[^,]*$)/, '');
      }

      // Check if the price has 3 or more numbers after the comma and remove the last one
      if (value.split(',')[1] && value.split(',')[1].length > 2) {
        value = value.replace(/.$/, '');
      }

      // Check if there are letters or special characters and remove them
      if (value.match(/[^0-9,]/g)) {
        value = value.replace(/[^0-9,]/g, '');
      }

      // Add dot as thousand separator
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      setSubtotalPrice(value);
      setManualSubtotalPrice(true);
      setIsDirty(true);
    },
    [subtotalPrice],
  );

  const subtotalPriceModal = (
    <Modal
      open={subtotalModalActive}
      onClose={() => {
        handleEditSubtotalModal();
        uploadQuoteFileActive && handleUploadQuoteFileModal();
        // Clean up the file upload
        setTmpFileUploadValue(null);
        setTxtFileUploadValue('');
        setFileUploadActive(false);
        setFileScanSuccessful(false);
        setButtonSpinning(false);
        setScanError(false);
        setSelectedType('quote');
      }}
      title={!uploadQuoteFileActive ? 'Prezzo di gestione' : 'Carica file nel preventivo'}
      primaryAction={
        !uploadQuoteFileActive || fileScanSuccessful || scanError
          ? {
              content: 'Salva',
              loading: uploadQuoteFileActive ? buttonSpinningUpload : false,
              onAction: () => {
                !uploadQuoteFileActive && setSubtotalPrice(subtotalPrice);
                !uploadQuoteFileActive && setTotalPrice(totalPrice);
                if (uploadQuoteFileActive) {
                  handleConfirmFileUpload(tmpSubtotalPrice, tmpTotalPrice);
                }
                // Clean up the file upload
                !uploadQuoteFileActive && handleEditSubtotalModal();
                setTmpFileUploadValue(null);
                setTxtFileUploadValue('');
                setFileUploadActive(false);
                setScanError(false);
                setSelectedType('quote');
              },
            }
          : undefined
      }
      secondaryActions={
        !uploadQuoteFileActive || fileScanSuccessful || scanError
          ? [
              {
                content: 'Annulla',
                onAction: () => {
                  handleEditSubtotalModal();
                  if (uploadQuoteFileActive) {
                    handleUploadQuoteFileModal();
                  }
                  // Clean up the file upload
                  setTmpFileUploadValue(null);
                  setTxtFileUploadValue('');
                  setFileUploadActive(false);
                  setFileScanSuccessful(false);
                  setButtonSpinning(false);
                  setScanError(false);
                  setSelectedType('quote');
                },
              },
            ]
          : undefined
      }
    >
      {uploadQuoteFileActive && (
        <Modal.Section>
          <DropZone allowMultiple={false} onDrop={handleAddFileUploadInModal}>
            {uploadedFile}
            {fileUpload}
          </DropZone>
          {scanErrorMarkup}
          {loadingBarFileScanMarkup}
        </Modal.Section>
      )}
      {error && (
        <div>
          <Banner title="Errore" status="critical">
            Si è verificato un errore durante il caricamento del file. Si prega di riprovare.
          </Banner>
        </div>
      )}
      {(!uploadQuoteFileActive || fileScanSuccessful || scanError) && (
        <>
          {renderPolicyDatesMarkup(selectedType === 'policy')}
          <Modal.Section>
            <TextField
              label="Subtotale"
              type="currency"
              value={subtotalPrice}
              placeholder="0,00"
              onChange={handleSubtotalChange}
              prefix="EUR"
              suffix="€"
              autoComplete="off"
              helpText="L'importo della polizza"
            />
          </Modal.Section>
        </>
      )}
    </Modal>
  );
  /**
   * Manage automation bot
   */
  const handleAutomationBot = async (automation_name: string) => {
    try {
      setHasAutomationStarted(true);
      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/orders/automation/${automation_name}`,
        {
          order_id: params.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );

      if (response.status === 200) {
        setAutomationBotSuccess(true);
      }
    } catch (error: AxiosError | any) {
      if (error.response) {
        const errorResponse = error.response.data.message || error.response.data.status;
        if (error.response.data.status === 'critical_automation_error') {
          // Block automation
          if (automation) {
            setAutomation({
              ...automation,
              error: true,
            });
          }
          return;
        }
        // Make lower case and capitalize first letter
        // const formattedError = errorResponse.charAt(0).toUpperCase() + errorResponse.slice(1).toLowerCase();
        setAutomationBotError(errorResponse);
      }
    }
  };

  /**
   * Fetch automation name
   */
  useEffect(() => {
    const fetchAutomation = async () => {
      try {
        console.log(lineItemsVariant);
        if (lineItemsVariant[0]?.has_automation_bot && lineItemsVariant[0]?.automation_name !== undefined && !hasAutomationStarted) {
          const response = await axios.get(
            (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/automations/${lineItemsVariant[0].automation_name}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
              },
            },
          );
          const data = response.data;

          if (data.status === 'success') {
            setAutomationBotError('');
            setAutomation(data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchAutomation();
  }, [lineItemsVariant]);

  /**
   * Update subtotal price and total price
   */
  useEffect(() => {
    let subtotalPriceTmp = 0;
    let totalPriceTmp = 0;
    let discountPriceTmp = 0;

    // for (const variant of lineItemsVariant) {
    //   if (variant.quotation_fields?.length === 0) {
    //     subtotalPriceTmp += Number(variant.price);
    //     totalPriceTmp += Number(variant.price);
    //   } else {
    //     subtotalPriceTmp += subtotalQuotationPrice;
    //     totalPriceTmp += subtotalQuotationPrice;
    //   }
    // }

    subtotalPriceTmp = Number(subtotalPrice.replace('.', '').replace(',', '.'));
    totalPriceTmp = Number(subtotalPrice.replace('.', '').replace(',', '.'));

    if (discount) {
      if (discount.value_type === 'percentage') {
        discountPriceTmp = subtotalPriceTmp * (discount.value / 100);
        totalPriceTmp = subtotalPriceTmp - discountPriceTmp;
      } else if (discount.value_type === 'fixed_amount') {
        discountPriceTmp = discount.value;
        totalPriceTmp = subtotalPriceTmp - discountPriceTmp;
      }
    }

    if (managementPrice) {
      totalPriceTmp += Number(managementPrice.replace('.', '').replace(',', '.'));
    }

    if (totalPriceTmp < 0) {
      totalPriceTmp = 0;
    }

    setSubtotalPrice(subtotalPriceTmp.toFixed(2).replace('.', ','));
    setDiscountPrice(discountPriceTmp.toFixed(2).replace('.', ','));
    setTotalPrice(totalPriceTmp.toFixed(2).replace('.', ','));
  }, [lineItemsVariant, discount, managementPrice, subtotalPrice]);

  /**
   * Products autocomplete
   */
  const updateProductText = useCallback(
    (value: any) => {
      setProductInputValue(value);

      if (!productLoading) {
        setProductLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setProductOptions(deselectedProductOptions);
          setProductLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedProductOptions.filter((option: any) => option.label.match(filterRegex));
        setProductOptions(resultOptions);
        setProductLoading(false);
      }, 300);
    },
    [deselectedProductOptions, productLoading],
  );

  const updateProductSelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.lineItems) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, lineItems: false }));
      }

      const selectedText = selected.map((selectedItem: any) => {
        const matchedOption = productOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });
      setSelectedProductOptions(selected);
      setProductInputValue(selectedText[0]);

      // Add variant to lineItems
      const variantId = selected[0];
      const newLineItemsVariant = lineItemsVariant.slice();
      const newLineItems = lineItems.slice();
      // Find variant with variantId from variants array
      const variant = variants.find((variant: any) => variant._id === variantId);
      // Check if variant is already present in previous array
      const index = newLineItemsVariant.findIndex((item: any) => item._id === variantId);

      if (index === -1 && variant !== undefined) {
        newLineItemsVariant.push(variant);
        setLineItemsVariant(newLineItemsVariant);
        newLineItems.push(variantId);
        setLineItems(newLineItems);
      }
    },
    [productOptions, lineItemsVariant, lineItems, variants],
  );

  const productTextField = (
    <Autocomplete.TextField
      onChange={updateProductText}
      label={null}
      value={productInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca un prodotto"
      autoComplete="off"
      error={emptyFields.lineItems && 'Per favore seleziona un prodotto'}
      disabled
    />
  );

  const emptyProductState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessun prodotto trovato</TextContainer>
      </div>
    </React.Fragment>
  );

  /**
   * Line items markup
   */
  const getProductPrice = (item: Variant) => {
    if (!item.quotation) {
      return item.sale_price ? item.sale_price : item.price;
    } else {
      const orderMetadata = metadata.find((meta: OrderMetadata) => meta.product === item._id);
      return orderMetadata?.fields.find((tmp: QuotationMetadata) => tmp.label === 'quotation' || tmp.api_field === 'quotation')?.value;
    }
  };

  /**
   * Render the value based on the type
   * @param value
   * @param type
   * @returns
   */
  const renderValue = (value: string | number, type: 'text' | 'number' | 'currency' | 'array' | 'date' | 'select') => {
    if (type === 'currency') {
      return (Number(value).toFixed(2) + ' €').replace('.', ',');
    }

    // If the value is an array of objects the display a list
    if (Array.isArray(value)) {
      let table = null;
      value.map((item: any) => {
        if (typeof item === 'object') {
          // Create a table having the keys as the header and the values as the content
          const contentTypes = Object.keys(item).map((key) => {
            if (typeof item[key] === 'number') {
              return 'numeric';
            }
            return 'text';
          });
          table = <DataTable columnContentTypes={contentTypes} headings={Object.keys(item)} rows={[Object.values(item)]} />;
        }
      });

      return table;
    }

    return value;
  };

  /**
   * Render product metadata
   * @param item
   * @returns
   */
  const renderProductMetadata = (item: Variant) => {
    if (item.quotation_fields && item.quotation_fields.length > 0) {
      const orderMetadata = metadata.find((meta: OrderMetadata) => meta.product === item._id);
      const fields = orderMetadata?.fields.filter((tmp: QuotationMetadata) => tmp.label !== 'quotation' && tmp.api_field !== 'quotation');
      return (
        <div>
          {fields?.map((field: QuotationMetadata) => {
            if (field.type === 'array') {
              return (
                <div key={field.label} style={{ marginBottom: fields.indexOf(field) < fields.length - 1 ? '.5rem' : 0 }}>
                  <Text as="span" color="subdued">
                    <span style={{ fontWeight: 'bold' }}>{field.label}</span>:
                    {(field.value as Array<Array<QuotationMetadata>>)?.map((item, index) => {
                      return (
                        <>
                          <ul
                            key={index}
                            style={{
                              marginTop: index > 0 ? '.5rem' : 0,
                              marginBottom: index < (field.value as Array<Array<QuotationMetadata>>).length - 1 ? '.5rem' : 0,
                            }}
                          >
                            {item.map((detail) => (
                              <li key={detail.api_field}>
                                <Text as="span" color="subdued">
                                  <span style={{ fontWeight: 'bold' }}>{detail.label}</span>: {detail.value as string | number}
                                </Text>
                              </li>
                            ))}
                          </ul>
                          {(field.value as Array<Array<QuotationMetadata>>).length > 1 &&
                            index < (field.value as Array<Array<QuotationMetadata>>).length - 1 && <Divider />}
                        </>
                      );
                    })}
                  </Text>
                </div>
              );
            }

            // Render other field types (non-array fields)
            return (
              <div key={field.label}>
                <Text as="span" color="subdued">
                  <span style={{ fontWeight: 'bold' }}>{field.label}</span>: {renderValue(field.value as string | number, field.type)}
                </Text>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const lineItemsTableMarkup =
    lineItemsVariant.length > 0 ? (
      <table className="order__productsTable">
        <thead>
          <tr>
            <th className="order__productsTableItem -left">Prodotto</th>
            <th className="order__productsTableItem -right">Totale</th>
            <th className="order__productsTableItem -left">
              <Text visuallyHidden as="span">
                Rimuovi
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {lineItemsVariant?.map((item: any) => (
            <tr className="order__productsTableRow" key={item._id}>
              <td className="order__productsTableRowItem -left">
                <LegacyStack vertical spacing="extraTight">
                  <Link url={`/admin/products/${item.product._id}/variants/${item._id}`}>
                    <Text as="span" fontWeight="semibold">
                      {item.name}
                    </Text>
                  </Link>
                  <p>{renderProductMetadata(item)}</p>
                </LegacyStack>
              </td>
              <td className="order__productsTableRowItem -right">
                <span>{Number(getProductPrice(item))?.toFixed(2).replace('.', ',')}€</span>
              </td>
              <td className="order__productsTableRowItem -left"></td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : null;

  /**
   * Commission line item markup
   */
  const commissionLineItemMarkup =
    lineItemsVariant.length > 0 ? (
      <>
        {lineItemsVariant?.map((item: any) => (
          <div key={item._id} className="paymentRow">
            <div className="paymentRowTitle">{item.name}</div>
            <div className="paymentRowAmount">{(commissions.find((x) => x.product === item._id)?.value || 0).toFixed(2).replace('.', ',')} €</div>
          </div>
        ))}
      </>
    ) : null;

  /**
   * Customer autocomplete
   */
  const updateCustomerText = useCallback(
    (value: any) => {
      setCustomerInputValue(value);

      if (!customerLoading) {
        setCustomerLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setCustomerOptions(deselectedCustomerOptions);
          setCustomerLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedCustomerOptions.filter((option: any) => option.label.match(filterRegex));
        setCustomerOptions(resultOptions);
        setCustomerLoading(false);
      }, 300);
    },
    [deselectedCustomerOptions, customerLoading],
  );

  const updateCustomerSelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.customer) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, customer: false }));
      }

      const selectedCustomer = selected.map((selectedItem: any) => {
        const matchedOption = customerOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption;
      });
      setSelectedCustomerOptions(selected);
      setCustomerInputValue(selectedCustomer[0].label);

      // Add customer to order
      const customer = selectedCustomer[0].customer;
      setCustomer(customer);
    },
    [customerOptions],
  );

  const customerTextField = (
    <Autocomplete.TextField
      onChange={updateCustomerText}
      label={null}
      value={customerInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca cliente"
      autoComplete="off"
      error={emptyFields.customer && 'Per favore seleziona un cliente'}
      disabled
    />
  );

  const emptyCustomerState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessun cliente trovato</TextContainer>
      </div>
    </React.Fragment>
  );

  /**
   * Customer markup
   *
   * Display autocomplete if customer is not selected
   * When customer is loaded, display customer details
   */
  const customerMarkup =
    customer === null ? (
      <LegacyCard sectioned title="Cliente">
        <LegacyStack vertical>
          <Autocomplete
            options={customerOptions}
            selected={selectedCustomerOptions}
            onSelect={updateCustomerSelection}
            loading={customerLoading}
            textField={customerTextField}
            emptyState={emptyCustomerState}
          />
        </LegacyStack>
      </LegacyCard>
    ) : (
      <LegacyCard>
        <LegacyCard.Header
          title="Cliente"
          actions={[
            {
              content: 'Vai al cliente',
              onAction: () => {
                window.open(`/customers/${customer._id}`, '_blank');
              },
            },
          ]}
        />
        <LegacyCard.Section>
          <VerticalStack gap="050">
            {order?.user.lastname ? (
              <Text as="p" fontWeight="semibold">
                {order?.user.lastname} {order?.user.name}
              </Text>
            ) : (
              <Text as="p" fontWeight="semibold">
                {order?.user.name}
              </Text>
            )}
            {order?.user?.email ? (
              <LegacyStack>
                <LegacyStack.Item fill>{order?.user.email}</LegacyStack.Item>
                <Button onClick={handleCopyEmail} icon={ClipboardMinor as any} plain />
              </LegacyStack>
            ) : (
              <p>
                <Badge>Email mancante</Badge>
              </p>
            )}
            {order?.user?.customer?.fiscal_code ? (
              <LegacyStack>
                <LegacyStack.Item fill>CF: {order.user.customer.fiscal_code}</LegacyStack.Item>
                <Button onClick={handleCopyCF} icon={ClipboardMinor as any} plain />
              </LegacyStack>
            ) : (
              <Text as="p" fontWeight="regular">
                <Badge>CF mancante</Badge>
              </Text>
            )}
            {order?.user?.customer?.vat ? (
              <LegacyStack>
                <LegacyStack.Item fill>P.IVA: {order.user.customer.vat}</LegacyStack.Item>
                <Button onClick={handleCopyVAT} icon={ClipboardMinor as any} plain />
              </LegacyStack>
            ) : (
              <p>
                <Badge>P.IVA mancante</Badge>
              </p>
            )}
            {order?.user?.customer?.ateco ? (
              <LegacyStack>
                <LegacyStack.Item fill>ATECO: {order.user.customer.ateco}</LegacyStack.Item>
                <Button onClick={handleCopyATECO} icon={ClipboardMinor as any} plain />
              </LegacyStack>
            ) : (
              <p>
                <Badge>ATECO mancante</Badge>
              </p>
            )}
          </VerticalStack>
        </LegacyCard.Section>
        <LegacyCard.Section title="Telefono">
          <LegacyCard.Subsection>
            <LegacyStack>
              <LegacyStack.Item fill>{order?.user?.phone ? <p>{order?.user.phone}</p> : <Badge>Mancante</Badge>}</LegacyStack.Item>
              <Button onClick={handleCopyPhone} icon={ClipboardMinor as any} plain />
            </LegacyStack>
          </LegacyCard.Subsection>
        </LegacyCard.Section>
        <LegacyCard.Section title="Indirizzo">
          <LegacyCard.Subsection>
            <LegacyStack>
              <LegacyStack.Item fill>
                {customer?.address && customer?.address.line.length > 0 ? (
                  <LegacyStack vertical spacing="none">
                    <p>{customer.address.line}</p>
                    <p>
                      {customer.address.zip} {customer.address.city}
                    </p>
                    <p>{customer.address.country}</p>
                  </LegacyStack>
                ) : (
                  <p>
                    <Badge>Mancante</Badge>
                  </p>
                )}
              </LegacyStack.Item>
              <Button onClick={handleCopyAddress} icon={ClipboardMinor as any} plain />
            </LegacyStack>
          </LegacyCard.Subsection>
        </LegacyCard.Section>
      </LegacyCard>
    );

  /**
   * Organization markup
   */
  const organizationMarkup = (
    <LegacyCard sectioned title="Organizzazione">
      <LegacyStack vertical>
        <Text as="p" fontWeight="semibold">
          {(order?.organization as Organization)?.name}
        </Text>
      </LegacyStack>
    </LegacyCard>
  );

  /**
   * Payment modal markup
   */
  const paymentModalMarkup = (
    <Modal
      open={paymentModalActive}
      onClose={handlePaymentModalChange}
      title="Pagamento"
      primaryAction={{
        content: 'Continua',
        onAction: handleAddPayment,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handlePaymentModalChange,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField autoComplete="on" suffix="€" type="text" label="Importo" value={amount} onChange={handleAmountChange} />
            <Select label="Metodo di pagamento" options={paymentOptions} onChange={handleSelectPaymentChange} value={selectedPaymentType} />
          </FormLayout.Group>
          <FormLayout.Group>
            <Button onClick={handleDatePaymentSelection}>Seleziona Data di Pagamento Polizza</Button>
            <TextField
              autoComplete="on"
              type="text"
              disabled={true}
              labelHidden={true}
              label="Data pagamento"
              value={selectedDatesPayment.start.toLocaleDateString('it', { day: '2-digit', month: '2-digit', year: 'numeric' })}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            {datePaymentSelection && (
              <DatePicker
                month={monthPayment}
                year={yearPayment}
                onChange={handleSelectedDatesPayment}
                onMonthChange={handleMonthPaymentChange}
                selected={selectedDatesPayment}
                allowRange={false}
                weekStartsOn={1}
              />
            )}
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Order status modal markup
   */
  const orderStatusModalMarkup = (
    <Modal
      open={orderStatusModalActive}
      onClose={handleOrderStatusModalChange}
      title="Cambia stato ordine"
      primaryAction={{
        content: 'Continua',
        onAction: () => handleOrderStatusChange(),
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleOrderStatusModalChange,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select label="Stato ordine" options={orderStatusOptions} onChange={handleSelectOrderStatusChange} value={selectedOrderStatus} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Automation markup
   */
  const automationBotErrorMarkup = automationBotError !== '' && (
    <div style={{ padding: '20px' }}>
      <LegacyCard.Section flush>
        <Banner title="Si è verificato un errore nell'automazione" status="critical" onDismiss={() => setAutomationBotError('')}>
          <p>{automationBotError}</p>
        </Banner>
      </LegacyCard.Section>
    </div>
  );

  const automationMarkup = automation && automation.available && automation.order_status === order?.status && (
    <LegacyCard>
      {!automation.error ? (
        <>
          <LegacyCard.Header title="Automazione registrazione dell'ordine" />
          {!automationBotSuccess ? (
            <>
              <LegacyCard.Section>
                <Button onClick={() => handleAutomationBot(lineItemsVariant[0].automation_name as string)}>
                  Registra l'ordine sul portale della compagnia
                </Button>
              </LegacyCard.Section>
            </>
          ) : (
            <div>
              <LegacyCard.Section>
                <Badge status="success">
                  {`Ordine registrato correttamente. ` + (order.policy_number_on_portal ? `Polizza numero: ${order.policy_number_on_portal}` : '')}
                </Badge>
              </LegacyCard.Section>
            </div>
          )}
          {automationBotErrorMarkup}
        </>
      ) : (
        <>
          <LegacyCard.Header title="Automazione registrazione dell'ordine" />
          <LegacyCard.Section>
            <Banner title="Automazione non disponibile" status="critical">
              <p>L'automazione non può essere utilizzata al momento, è rischiesto intervento tecnico.</p>
            </Banner>
          </LegacyCard.Section>
        </>
      )}
    </LegacyCard>
  );

  /** Automation Toast */
  const toggleAutomationStarted = useCallback(() => setHasAutomationStarted((hasAutomationStarted) => !hasAutomationStarted), []);
  const toastAutomationMarkup = hasAutomationStarted ? <Toast content="Operazione avviata" onDismiss={toggleAutomationStarted} /> : null;

  /**
   * Payments markup
   */
  const paymentsMarkup = (order?.status === 'quote_accepted' || order?.status === 'paid' || order?.status === 'emitted') && (
    <LegacyCard
      title="Incassi"
      actions={[
        {
          content: 'Aggiungi incasso',
          onAction: handlePaymentModalChange,
          disabled: order?.status === 'emitted',
        },
      ]}
    >
      <ResourceList
        resourceName={{ singular: 'payment', plural: 'payments' }}
        items={payments}
        emptyState={
          !payments.length ? (
            <EmptyState
              heading="Aggiungi un incasso per iniziare"
              action={{
                content: 'Aggiungi incasso',
                onAction: handlePaymentModalChange,
              }}
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/4b286a5ed8413c9d0bebb550e3ac2f6d2b7568f578eb8ddcc891fb50ec64c851.svg"
            >
              <p>Puoi usare questa sezione per aggiungere gli incassi</p>
            </EmptyState>
          ) : undefined
        }
        renderItem={(item: Transaction, index: string) => {
          const { amount, type, date_paid } = item;
          const date = new Date(date_paid).toLocaleDateString('it', { day: '2-digit', month: '2-digit', year: 'numeric' });
          console.log(date, item);

          // Fetch payment type
          const getPaymentType = (type: string) => {
            const res = paymentOptions.filter((option) => option.value === type);
            return res[0].label;
          };
          const paymentType = type !== undefined ? `${getPaymentType(type)}` : <Badge>Mancante</Badge>;

          return (
            <ResourceItem
              id={index}
              accessibilityLabel={`View details for ${amount}`}
              onClick={() => {
                return;
              }}
            >
              <LegacyStack alignment="center" distribution="equalSpacing">
                <LegacyStack vertical>
                  <div>
                    <h3>
                      <Text as="span" fontWeight="semibold">
                        {Number(amount).toFixed(2)} €
                      </Text>
                    </h3>
                    <div>
                      {paymentType} - {date}
                    </div>
                  </div>
                </LegacyStack>
                <LegacyStack>
                  <Button plain destructive={true} onClick={() => handleDeletePayment(item._id)} loading={buttonSpinningDelete}>
                    Elimina
                  </Button>
                </LegacyStack>
              </LegacyStack>
            </ResourceItem>
          );
        }}
      />
    </LegacyCard>
  );

  /**
   * Management price modal & handlers
   */
  const handleManagementPriceChange = useCallback(
    (value: any) => {
      // Check if the price has 2 or more commas and remove the last one
      if (value.split(',').length > 2) {
        value = value.replace(/,(?=[^,]*$)/, '');
      }

      // Check if the price has 3 or more numbers after the comma and remove the last one
      if (value.split(',')[1] && value.split(',')[1].length > 2) {
        value = value.replace(/.$/, '');
      }

      // Check if there are letters or special characters and remove them
      if (value.match(/[^0-9,]/g)) {
        value = value.replace(/[^0-9,]/g, '');
      }

      // Add dot as thousand separator
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      setManagementPrice(value);
    },
    [managementPrice],
  );

  /**
   * File dropzones markup
   */
  const fileDropzonesMarkup = (
    <div>
      <LegacyStack vertical>
        <LegacyStack distribution="fill">
          {(order?.status === 'quote' || order?.status === 'to_be_quoted') && (
            <LegacyStack vertical spacing="tight">
              <h3>
                <b>Preventivo</b>
              </h3>
              <DropZone
                allowMultiple={false}
                onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => {
                  handleAddFileUploadInModal(_dropFiles, acceptedFiles, _rejectedFiles);
                  setSelectedType('quote');
                }}
              >
                <DropZone.FileUpload />
              </DropZone>
            </LegacyStack>
          )}
          {(order?.status === 'quote' || order?.status === 'paid') && (
            <LegacyStack vertical spacing="tight">
              <h3>
                <b>Polizza</b>
              </h3>
              <DropZone
                allowMultiple={false}
                onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => {
                  handleAddFileUploadInModal(_dropFiles, acceptedFiles, _rejectedFiles);
                  setSelectedType('policy');
                }}
              >
                <DropZone.FileUpload />
              </DropZone>
            </LegacyStack>
          )}
        </LegacyStack>

        <LegacyStack distribution="center">
          {selectedFileUpload[0] && <Button onClick={() => handleOpenFile(selectedFileUpload[0])}>Apri</Button>}
          {selectedFileUpload[0] && <Button onClick={() => handleDelFileUpload(selectedFileUpload[0])}>Elimina</Button>}
        </LegacyStack>

        {/* New ResourceList-based file view */}
        <ResourceList
          resourceName={{ singular: 'documento', plural: 'documenti' }}
          items={optionsFileUpload}
          renderItem={(file) => (
            <ResourceItem id={file.value} onClick={() => handleOpenFile(file.value)}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon source={NoteMinor as any} />
                  <span style={{ marginLeft: '8px' }}>{file.label}</span>
                </div>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Tooltip content="Scarica" preferredPosition="above">
                    <div onClick={(e) => e.stopPropagation()}>
                      <Button plain icon={<Icon source={AddNoteMajor as any} />} onClick={() => handleOpenFile(file.value)} />
                    </div>
                  </Tooltip>
                  <Tooltip content="Elimina" preferredPosition="above">
                    <div onClick={(e) => e.stopPropagation()}>
                      <Button plain icon={<Icon source={DeleteMajor as any} />} onClick={() => handleDelFileUpload(file.value)} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </ResourceItem>
          )}
        />
      </LegacyStack>

      <div>
        <Modal
          open={activeFileUpload}
          onClose={toggleModalFileUpload}
          title={isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
          secondaryActions={[
            {
              content: 'Chiudi',
              onAction: toggleModalFileUpload,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <FormLayout.Group>
                <DropZone onDrop={handleTmpFileUploadValue}>
                  {uploadedFile}
                  {fileUpload}
                </DropZone>
              </FormLayout.Group>
              <FormLayout.Group>
                <Select label="Tipologia di documento" options={typeOptions} onChange={(e: OrderFileType) => setSelectedType(e)} value={selectedType} />
              </FormLayout.Group>
              {renderPolicyDatesMarkup(selectedType === 'policy')}
              <FormLayout.Group>
                <Button primary onClick={handleConfirmFileUpload} loading={buttonSpinningUpload}>
                  {isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
                </Button>
              </FormLayout.Group>
            </FormLayout>
          </Modal.Section>
        </Modal>
      </div>
    </div>
  );

  /**
   * Harp pricing feature
   */
  const harpFeatureMarkup = [];
  harpFeatureMarkup.push(
    <div className="paymentRow">
      <div className="paymentRowTitle">Fattura Generali</div>
      <div className="paymentRowDetail"></div>
      <div className="paymentRowAmount">{generaliPrice} €</div>
    </div>,
  );
  harpFeatureMarkup.push(
    <div className="paymentRow">
      <div className="paymentRowTitle">Fattura Saluzzo Broker</div>
      <div className="paymentRowDetail"></div>
      <div className="paymentRowAmount">{sbPrice} €</div>
    </div>,
  );

  const handleManagementPriceModal = useCallback(() => {
    setManagementPriceModalActive(!managementPriceModalActive);
  }, [managementPriceModalActive]);

  const managementPriceModal = (
    <Modal
      open={managementPriceModalActive}
      onClose={handleManagementPriceModal}
      title="Prezzo di gestione"
      primaryAction={{
        content: 'Salva',
        onAction: () => {
          handleManagementPriceModal();
        },
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => handleManagementPriceModal(),
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label="Prezzo di gestione"
          type="currency"
          value={managementPrice}
          placeholder="0,00"
          onChange={handleManagementPriceChange}
          prefix="EUR"
          suffix="€"
          autoComplete="off"
          helpText="L'importo per la gestione della polizza"
        />
      </Modal.Section>
    </Modal>
  );

  const fileUploadJSX = (
    <div>
      <LegacyStack vertical>
        {/* DropZone per il caricamento dei file */}
        <DropZone allowMultiple={false} onDrop={handleAddFileUpload} disabled={missingUserError}>
          <DropZone.FileUpload />
        </DropZone>
        <Layout>
          <Layout.Section>
            <ResourceList
              resourceName={{ singular: 'documento', plural: 'documenti' }}
              items={optionsFileUpload}
              renderItem={(file) => {
                return (
                  <ResourceItem id={file.value} onClick={() => handleOpenFile(file.value)}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon source={NoteMinor as any} />
                        <span style={{ marginLeft: '8px' }}>{file.label}</span>
                      </div>
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <Tooltip content="Scarica" preferredPosition="above">
                          <div onClick={(e) => e.stopPropagation()}>
                            <Button plain icon={<Icon source={AddNoteMajor as any} />} onClick={() => handleOpenFile(file.value)} />
                          </div>
                        </Tooltip>
                        <Tooltip content="Elimina" preferredPosition="above">
                          <div onClick={(e) => e.stopPropagation()}>
                            <Button plain icon={<Icon source={DeleteMajor as any} />} onClick={() => handleDelFileUpload(file.value)} />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </ResourceItem>
                );
              }}
            />
          </Layout.Section>
        </Layout>
      </LegacyStack>
      <div>
        <Modal
          open={activeFileUpload}
          onClose={toggleModalFileUpload}
          title={isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
          secondaryActions={[
            {
              content: 'Chiudi',
              onAction: toggleModalFileUpload,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <FormLayout.Group>
                <DropZone onDrop={handleTmpFileUploadValue}>
                  {uploadedFile}
                  {fileUpload}
                </DropZone>
              </FormLayout.Group>
              <FormLayout.Group>
                <Select label="Tipologia di documento" options={typeOptions} onChange={(e: OrderFileType) => setSelectedType(e)} value={selectedType} />
              </FormLayout.Group>
              {renderPolicyDatesMarkup(selectedType === 'policy')}
              <FormLayout.Group>
                <Button primary onClick={handleConfirmFileUpload} loading={buttonSpinningUpload}>
                  {isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
                </Button>
              </FormLayout.Group>
            </FormLayout>
          </Modal.Section>
        </Modal>
      </div>
    </div>
  );

  /**
   * Order timeline markup
   */
  const orderTimelineList = [];
  if (order?.timeline) {
    for (const orderTimeline of order.timeline) {
      orderTimelineList.push(
        <div key={`${orderTimeline.status}-${orderTimeline.date_updated}`} className="polizzeSectionBlock">
          <div>
            <div className="polizzeSectionSubheading ">{parseDateLabel(new Date(orderTimeline.date_updated))}</div>
            <ul className="polizzeSectionUl">
              <li className="polizzeSectionLi">
                <div className="polizzeSectionMargin">
                  <div className="polizzeSectionText">
                    <div className="polizzeSectionPoint"></div>
                    <p className="polizzeSectionP">{parseOrderTimelineStatus(orderTimeline.status)}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>,
      );
    }
  }

  const orderTimelineListMarkup = (
    <>
      {/** Polizze */}
      <div className="polizzeSectionHeading">
        <Text variant="headingMd" as="h2">
          Timeline
        </Text>
      </div>
      <div>
        <div className="polizzeSectionInitial">
          <div className="polizzeSectionAvatar">
            <Avatar customer={false} size="medium" initials={'C'} name={'_id'} />
          </div>
        </div>
        <div>{orderTimelineList}</div>
      </div>
    </>
  );

  /**
   * Contextual Save Bar
   */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);

    // Reset all the fields
  }, []);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message={'Modifiche non salvate'}
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Order buttons markup & handlers
   */
  const renderOrderButton = () => {
    switch (order?.status) {
      case 'quote':
      case 'quote_accepted':
        return (
          <LegacyStack spacing="tight" distribution="trailing">
            <Button onClick={handlePrintOrder} disabled={lineItems.length === 0 || customer === null} loading={buttonSpinning}>
              Stampa preventivo
            </Button>
            <Button primary onClick={handleSendOrderInvoice} disabled={lineItems.length === 0 || customer === null} loading={buttonSpinningEmail}>
              Invia preventivo
            </Button>
          </LegacyStack>
        );
      case 'paid':
      case 'emitted':
        return (
          <LegacyStack spacing="tight" distribution="trailing">
            <Button onClick={handlePrintOrder} disabled={lineItems.length === 0 || customer === null} loading={buttonSpinning}>
              Stampa ricevuta
            </Button>
            <Button primary onClick={handleSendOrderInvoice} disabled={lineItems.length === 0 || customer === null} loading={buttonSpinningEmail}>
              Invia ricevuta
            </Button>
          </LegacyStack>
        );
      default:
        return null;
    }
  };

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;

  const toastPaymentMarkup = paymentActive ? <Toast content="L'incasso è stato aggiunto con successo." onDismiss={togglePaymentActive} /> : null;

  const toastDeletePaymentMarkup = deletePaymentActive ? (
    <Toast content="L'incasso è stato rimosso con successo." onDismiss={toggleDeletePaymentActive} />
  ) : null;

  const toastInvoiceSentMarkup = invoiceSentActive ? <Toast content="L'email è stata inviata con successo" onDismiss={toggleInvoiceSentActive} /> : null;

  const toastFileUploadMarkup = fileUploadActive ? <Toast content="Il file è stato caricato con successo" onDismiss={toggleFileUploadActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const amountErrorMarkup = amountError && (
    <Layout.Section>
      <Banner title="L'importo inserito è maggiore di quanto dovuto" status="critical" onDismiss={() => setAmountError(false)}>
        <p>Si è pregati di controllare e riprovare.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const paymentButton =
    order !== null && order.status === 'quote_accepted'
      ? {
          key: 'payment',
          content: 'Aggiungi pagamento',
          onAction: () => handlePaymentModalChange(),
        }
      : {};

  const pageTitle =
    lineItemsVariant.length > 1
      ? `${lineItemsVariant[0].product.name} ${lineItemsVariant[0].name} + ${lineItemsVariant.length - 1} altri`
      : lineItemsVariant.length === 1
      ? `${lineItemsVariant[0].product.name} - ${lineItemsVariant[0].name}`
      : null;

  const actualPageMarkup = (
    <Page
      title={`${order?.user?.lastname ? order?.user.lastname + ' ' + order?.user.name : order?.user?.name} - ${lineItemsVariant.length > 0 && pageTitle}`}
      titleMetadata={renderOrderStatusBadge(order?.status)}
      backAction={{ content: 'Polizze', url: '/policies' }}
      secondaryActions={[
        {
          key: 'status',
          content: 'Cambia stato',
          onAction: () => handleOrderStatusModalChange(),
        },
        paymentButton,
      ]}
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {amountErrorMarkup}

        <Layout.Section>
          {/* Products */}
          <LegacyCard title="Prodotti">
            <LegacyCard.Section>
              <Autocomplete
                options={productOptions}
                selected={selectedProductOptions}
                onSelect={updateProductSelection}
                loading={productLoading}
                textField={productTextField}
                emptyState={emptyProductState}
              />
            </LegacyCard.Section>
            <div>{lineItemsTableMarkup}</div>
          </LegacyCard>

          {/* Policy dates */}
          {renderPolicyDatesMarkup(order?.status === 'paid')}

          {/** Files Dropzone */}
          {['quote', 'to_be_quoted', 'paid'].includes(order?.status ?? '') && (
            <LegacyCard title="Documenti (con scan prezzo subtotale)">
              <LegacyCard.Section>{fileDropzonesMarkup}</LegacyCard.Section>
            </LegacyCard>
          )}

          {/* Commission */}
          {commissions.length > 0 && (
            <LegacyCard title="Provvigioni">
              <LegacyCard.Section>{commissionLineItemMarkup}</LegacyCard.Section>
            </LegacyCard>
          )}

          {/* Payment */}
          <LegacyCard title="Pagamento">
            <LegacyCard.Section>
              {/* Subtotal */}
              <div className="paymentRow">
                <div className="paymentRowTitle">Subtotale</div>
                <div className="paymentRowDetail">
                  <Button plain onClick={handleEditSubtotalModal}>
                    Modifica
                  </Button>
                </div>
                <div className="paymentRowAmount">{subtotalPrice} €</div>
              </div>
              {/* Harp feature */}
              {lineItemsVariant.find((x) => x.name === 'Arpa Sicura') && harpFeatureMarkup}
              {/* Discount */}
              <div className="paymentRow">
                <div className="paymentRowTitle">
                  <Button plain onClick={handleDiscountModal}>
                    {discount?.value !== undefined && discount?.value > 0 ? 'Modifica sconto' : 'Aggiungi sconto'}
                  </Button>
                </div>
                <div className="paymentRowDetail">
                  <Text as="span" color="subdued">
                    {discount ? discount.type === 'discount_code' ? 'Codice sconto' : 'Sconto personalizzato' : <>&mdash;</>}
                  </Text>
                </div>
                <div className="paymentRowAmount">
                  <Text as="span" color="subdued">
                    {discountPrice} €
                  </Text>
                </div>
              </div>
              {/* Management amount */}
              <div className="paymentRow">
                <div className="paymentRowTitle">
                  <Button plain onClick={handleManagementPriceModal}>
                    {managementPrice !== '' ? 'Modifica gestione' : 'Aggiungi gestione'}
                  </Button>
                </div>
                <div className="paymentRowDetail">
                  <Text as="span" color="subdued">
                    {managementPrice !== '' ? 'Importo personalizzato' : <>&mdash;</>}
                  </Text>
                </div>
                <div className="paymentRowAmount">
                  <Text as="span" color="subdued">
                    {managementPrice === '' ? '0,00' : Number(managementPrice.replace('.', '').replace(',', '.')).toFixed(2).replace('.', ',')} €
                  </Text>
                </div>
              </div>
              {/* Total */}
              <div className="paymentRow">
                <div className="paymentRowTitle">
                  <Text as="span" fontWeight="semibold">
                    Totale
                  </Text>
                </div>
                <div className="paymentRowAmount">{totalPrice} €</div>
              </div>
            </LegacyCard.Section>

            <LegacyCard.Section>{renderOrderButton()}</LegacyCard.Section>
          </LegacyCard>

          {/* Payments */}
          {paymentsMarkup}

          {/* Automation Bot */}
          {automationMarkup}

          {/* Files */}
          <LegacyCard title="Documenti">
            <LegacyCard.Section>{fileUploadJSX}</LegacyCard.Section>
          </LegacyCard>

          {/* Order timeline */}
          {orderTimelineListMarkup}
        </Layout.Section>

        <Layout.Section secondary>
          {/* Customer */}
          {customerMarkup}

          {/* Organization */}
          {organizationMarkup}

          {/* Categories */}
          <LegacyCard sectioned title="Note">
            <FormLayout>
              <TextField label={null} value={notes} onChange={handleNotesChange} autoComplete="off" />
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section></Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  // ---- Error ----
  const errorPageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <EmptyState
              heading="Nessun ordine presente a questo indirizzo"
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
            >
              <p>Controlla l'URL e riprova oppure usa la barra di ricerca per trovare ciò di cui hai bisogno.</p>
            </EmptyState>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : error ? errorPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {DiscountModal(discountModalActive, setDiscountModalActive, discount, setDiscount, newDiscount)}
      {orderStatusModalMarkup}
      {subtotalPriceModal}
      {paymentModalMarkup}
      {managementPriceModal}
      {toastMarkup}
      {toastAutomationMarkup}
      {toastPaymentMarkup}
      {toastDeletePaymentMarkup}
      {toastInvoiceSentMarkup}
      {toastFileUploadMarkup}
      {toastCopyMarkup}
    </Frame>
  );
}
