import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';
import { verifyToken } from './Common';
import { Layout, LegacyCard, SkeletonBodyText, TextContainer, SkeletonDisplayText, Frame, Loading } from '@shopify/polaris';
import axios from 'axios';

type ContextType = { user: any | null };

/**
 * Private route component
 * Compatible with react-router-dom v6
 */

const skeletonPageMarkup = (
  <div>
    <Frame>
      <Loading />
      {/* Top Bar with Logo */}
      <div
        style={{
          backgroundColor: '#fff',
          padding: '16px',
          borderBottom: '1px solid #dfe3e8',
        }}
      >
        <SkeletonDisplayText size="small" />
      </div>

      <div style={{ display: 'flex' }}>
        {/* Sidebar Section */}
        <div
          style={{
            width: '15%', // Smaller width for the sidebar
            padding: '16px',
            backgroundColor: '#f4f6f8',
          }}
        >
          <TextContainer>
            {/* Loop through to generate skeleton items for the navbar */}
            {Array(12) // Generate 12 lines
              .fill(0)
              .map((_, index) => (
                <div key={index} style={{ padding: '16px' }}>
                  <SkeletonBodyText lines={1} />
                </div>
              ))}
          </TextContainer>
        </div>

        {/* Main Content Section */}
        <div style={{ flex: 1, padding: '16px' }}>
          <Layout.Section>
            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={8} />
              </TextContainer>
            </LegacyCard>

            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </TextContainer>
            </LegacyCard>

            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={5} />
              </TextContainer>
            </LegacyCard>

            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </LegacyCard>
          </Layout.Section>
        </div>
      </div>
    </Frame>
  </div>
);

export function PrivateRoute({ component: Component, path, roles, organization, ...rest }: any) {
  const [auth, setAuth] = useState(false);
  const [goOn, setGoOn] = useState(false);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const checkAndProceed = async () => {
      try {
        // Check if there is a temp_code in the URL
        const params = new URLSearchParams(window.location.search);
        const tempCode = params.get('temp_code');

        // If there is a temp_code, verify it
        if (tempCode) {
          const res = await axios.post((process.env.REACT_APP_API_URL ?? '/api') + '/verify-temp-code', { temporary_code: tempCode }, { timeout: 5000 });
          if (res.data.status === 'success') {
            console.log(res.data);
            // Save the access token in localStorage
            localStorage.setItem('mb__access_token', res.data.token);
            // Remove the temp_code from the URL
            params.delete('temp_code');
            window.history.replaceState({}, '', window.location.pathname);
          } else {
            console.error('temp_code not valid', res.data);
          }
        }

        // Verify the token
        const result = await verifyToken();
        if (result === false) {
          setAuth(false);
        } else {
          setAuth(result.status);
          if (result.data) {
            setUser(result.data.user);
          }
        }
      } catch (err) {
        console.error('Error with temp_code:', err);
      } finally {
        setGoOn(true);
      }
    };

    checkAndProceed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!goOn) {
    return skeletonPageMarkup;
  }

  // If the user is not authenticated, redirect to the sso login page
  if (!auth && process.env.REACT_APP_SSO_LOGIN_URL) {
    window.location.href = process.env.REACT_APP_SSO_LOGIN_URL ?? '/';
    return null;
  }

  // If the user is authenticated and the role is customer, redirect to the user dashboard
  if (user && user.role === 'customer' && process.env.REACT_APP_USER_DASHBOARD_URL) {
    window.location.href = process.env.REACT_APP_USER_DASHBOARD_URL;
    return null;
  }

  // Check if the user has the required role to access the page
  if ((roles && roles.includes(user?.role)) || !roles) {
    return <Outlet context={{ user }} />;
  } else if (organization && user && user.organization_id && user.organization_id.length > 0) {
    return <Outlet context={{ user }} />;
  } else {
    return <Navigate to="/" />;
  }
}

export function useUser() {
  return useOutletContext<ContextType>();
}
