/**
 * Modal to create a new quotation
 */
import { Banner, Button, Card, FormLayout, IconSource, LegacyStack, Modal, Select, Text, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { OrderMetadata, PricingRule, QuotationField, QuotationMetadata } from '../../types';
import { DeleteMinor, PlusMinor } from '@shopify/polaris-icons';
import axios from 'axios';

/**
 * Render quotation field markup
 *
 * Note: For array type, we expect the metadata value to be an array of groups,
 * where each group is an array of QuotationMetadata (one per sub‑field).
 */
type Props = {
  metadataField: QuotationMetadata | undefined;
  name: string;
  type: string;
  resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none';
  fieldValue: string;
  /** The quotation fields values: can be populated with the select options or other quotation fields */
  values?: Array<{ label: string; value: string }> | QuotationField[] | QuotationMetadata[];
  /** The quotation field API field name */
  api_field?: string;
  handleSubtotal: (value: string, resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none', fieldValue: string) => void;
  handleMetadata: (
    value: string,
    name: string,
    type: 'text' | 'number' | 'currency' | 'array' | 'date' | 'select',
    api_field?: string,
    groupIndex?: number,
  ) => void;
  addNewArrayItem: (arrayFieldName: string) => void;
  removeArrayItem?: (arrayFieldName: string, groupIndex: number) => void;
  /** When rendering a sub-field inside an array, groupIndex indicates which group (array item) we are updating */
  groupIndex?: number;
};

const QuotationFieldComponent = ({
  metadataField,
  name,
  type,
  resultModifierType,
  fieldValue,
  values,
  api_field,
  handleSubtotal,
  handleMetadata,
  addNewArrayItem,
  removeArrayItem,
  groupIndex,
}: Props) => {
  const [value, setValue] = useState(
    metadataField?.type === 'select' ? (values as Array<{ label: string; value: string }>)?.[0]?.value : metadataField?.value || '',
  );
  const [quotationFieldsValues, setQuotationFieldsValues] = useState(values ?? []);

  const handleCurrencyChange = useCallback(
    (e: string) => {
      // Sanitize and format currency input
      if (e.split(',').length > 2) {
        e = e.replace(/,(?=[^,]*$)/, '');
      }
      if (e.split(',')[1] && e.split(',')[1].length > 2) {
        e = e.replace(/.$/, '');
      }
      if (e.match(/[^0-9,]/g)) {
        e = e.replace(/[^0-9,]/g, '');
      }
      e = e.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      setValue(e);
      handleSubtotal(e.replace('.', ''), resultModifierType, fieldValue);
      handleMetadata(e.replace('.', '').replace(',', '.'), name, 'currency', api_field, groupIndex);
    },
    [handleMetadata, handleSubtotal, name, resultModifierType, fieldValue, api_field, groupIndex],
  );

  const handleValueChange = useCallback(
    (e: string) => {
      if (e.split(',').length > 2) {
        e = e.replace(/,(?=[^,]*$)/, '');
      }
      if (e.split(',')[1] && e.split(',')[1].length > 2) {
        e = e.replace(/.$/, '');
      }
      if (e.match(/[^0-9,]/g)) {
        e = e.replace(/[^0-9,]/g, '');
      }
      e = e.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      setValue(e);
      handleSubtotal(e.replace('.', ''), resultModifierType, fieldValue);
      handleMetadata(e.replace('.', '').replace(',', '.'), name, 'number', api_field, groupIndex);
    },
    [handleMetadata, handleSubtotal, name, resultModifierType, fieldValue, api_field, groupIndex],
  );

  const handleTextChange = useCallback(
    (e: string, type: 'text' | 'number' | 'currency' | 'array' | 'date' | 'select') => {
      setValue(e);
      handleMetadata(e, name, type, api_field, groupIndex);
    },
    [handleMetadata, name, api_field, groupIndex],
  );

  /**
   * For an array field, we assume metadataField.value is an array of groups.
   */
  const renderArrayField = useCallback(() => {
    const groups: QuotationMetadata[][] =
      Array.isArray(metadataField?.value) && Array.isArray(metadataField?.value[0]) ? (metadataField?.value as QuotationMetadata[][]) : [];
    return (
      <LegacyStack vertical spacing="loose">
        {groups.map((group, idx) => (
          <LegacyStack.Item key={idx}>
            <Card>
              <FormLayout>
                <Text variant="headingMd" as="h3">
                  {`${name} ${idx + 1}`}
                </Text>
                {group.map((field, fieldIndex) => {
                  const currentField = field as any;

                  const quotationFieldValue = (quotationFieldsValues as QuotationField[]).find(
                    (value) => value.name === currentField.name || value.name === currentField.label,
                  )?.values;
                  return (
                    <QuotationFieldComponent
                      key={fieldIndex}
                      metadataField={currentField}
                      name={currentField.name || currentField.label}
                      type={currentField.type}
                      resultModifierType={currentField.result_modifier_type || 'none'}
                      fieldValue={currentField.result_modifier_value || '0'}
                      values={Array.isArray(quotationFieldValue) ? (quotationFieldValue as QuotationMetadata[]) : undefined}
                      api_field={currentField.api_field}
                      handleSubtotal={handleSubtotal}
                      handleMetadata={(val, fieldName, fieldType, fieldApiField) =>
                        // Pass the current group index so that metadata gets updated for the correct group
                        handleMetadata(val, fieldName, fieldType, fieldApiField, idx)
                      }
                      addNewArrayItem={addNewArrayItem}
                      removeArrayItem={removeArrayItem}
                    />
                  );
                })}
                {removeArrayItem && (
                  <Button onClick={() => removeArrayItem(name, idx)} icon={DeleteMinor as IconSource} destructive>
                    Rimuovi {name}
                  </Button>
                )}
              </FormLayout>
            </Card>
          </LegacyStack.Item>
        ))}
        <LegacyStack.Item>
          <Button onClick={() => addNewArrayItem(name)} icon={PlusMinor as IconSource}>
            Aggiungi {name}
          </Button>
        </LegacyStack.Item>
      </LegacyStack>
    );
  }, [metadataField, name, handleSubtotal, handleMetadata, addNewArrayItem, removeArrayItem]);

  const renderMarkup = (type: string) => {
    switch (type) {
      case 'currency':
        return (
          <TextField
            autoComplete="off"
            label={name}
            type="currency"
            placeholder="0,00"
            value={value.toString()}
            onChange={handleCurrencyChange}
            min={0}
            step={0.01}
            prefix="EUR"
            suffix="€"
          />
        );
      case 'number':
      case 'percentage':
        return <TextField autoComplete="off" label={name} type="number" value={value.toString()} onChange={handleValueChange} />;
      case 'text':
        return (
          <TextField
            autoComplete="off"
            label={name}
            type="text"
            helpText="Verrà visualizzato nel preventivo"
            value={value.toString()}
            onChange={(e: string) => handleTextChange(e, 'text')}
          />
        );
      case 'array':
        return renderArrayField();
      case 'date':
        return <TextField autoComplete="off" label={name} type="date" value={value as string} onChange={(e: string) => handleTextChange(e, 'date')} />;
      case 'select':
        return (
          <Select
            label={name}
            options={quotationFieldsValues as Array<{ label: string; value: string }>}
            onChange={(e) => {
              setValue(e);
              handleMetadata(e, name, 'select', api_field, groupIndex);
            }}
            // value={(quotationFieldsValues as Array<{ label: string; value: string }>).find((option) => option.value === value)?.value}
            value={value as string}
          />
        );
      default:
        return (
          <TextField
            autoComplete="off"
            label={name}
            type="currency"
            placeholder="0,00"
            value={value.toString()}
            onChange={handleCurrencyChange}
            min={0}
            step={0.01}
            prefix="EUR"
            suffix="€"
          />
        );
    }
  };

  return renderMarkup(type);
};

/**
 * The QuotationModal component builds the quotation metadata from the given
 * quotationFields. For array fields, we now store the value as an array of groups.
 */
export const QuotationModal = (
  variantId: string,
  quotationFields: QuotationField[] | null,
  orderMetadata: OrderMetadata | undefined,
  pricing_rules: PricingRule[] | undefined,
  active: boolean,
  setActive: any,
  onSave: any,
  onMetadata: any,
  userId?: string,
) => {
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [existError, setExistError] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  // Use local state for metadata to drive rendering
  const [quotationMetadata, setQuotationMetadata] = useState<QuotationMetadata[]>([]);

  // Initialize metadata from quotationFields
  useEffect(() => {
    const tmp: QuotationMetadata[] = [];

    quotationFields?.forEach((field) => {
      if (field.type === 'text') {
        tmp.push({
          label: field.name,
          value: '',
          type: 'text',
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'percentage' || field.type === 'number') {
        tmp.push({
          label: field.name,
          value: 0,
          type: 'number',
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'currency') {
        tmp.push({
          label: field.name,
          value: 0,
          type: 'currency',
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'array') {
        // For an array field, initialize with one group (if field.values exists)
        const groups: QuotationMetadata[][] = [];
        if (field.values && field.values.length > 0) {
          const group = (field.values as QuotationField[]).map(
            (value): QuotationMetadata => ({
              label: value.name,
              value: '',
              type: value.type as 'number' | 'text' | 'currency',
              api_field: value.api_field_name ?? value.name,
            }),
          );
          groups.push(group);
        }
        tmp.push({
          label: field.name,
          type: 'array',
          value: groups,
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'date') {
        tmp.push({
          label: field.name,
          value: '',
          type: 'date',
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'select') {
        tmp.push({
          label: field.name,
          value: '',
          type: 'select',
          api_field: field.api_field_name ?? field.name,
        });
      }
    });

    setQuotationMetadata(tmp);
  }, [quotationFields, variantId]);

  /**
   * Handle subtotal calculation
   */
  const handleSubtotal = (value: string, resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none', fieldValue: string) => {
    if (value.includes(',')) {
      value = value.replace(',', '.');
    }
    if (!isNaN(parseFloat(value))) {
      let newValue = parseFloat(value);
      if (resultModifierType === 'percentage') {
        newValue = (newValue / 100) * parseFloat(fieldValue.includes(',') ? fieldValue.replace(',', '.') : fieldValue);
      }
      setSubtotal(subtotal + newValue);
      if (pricing_rules) {
        pricing_rules.forEach((rule) => {
          if (rule.name === 'minimum_price') {
            if (rule.type === 'percentage') {
              setSubtotal(((subtotal + newValue) / 100) * Number(rule.value));
            } else if (rule.type === 'fixed_amount' && subtotal + newValue < Number(rule.value)) {
              setSubtotal(Number(rule.value));
            }
          }
        });
      }
    }
  };

  /**
   * Update metadata – for array fields we update the proper group based on groupIndex.
   */
  const handleMetadata = useCallback(
    (value: string, name: string, fieldType: 'text' | 'number' | 'currency' | 'array' | 'date' | 'select', api_field?: string, groupIndex?: number) => {
      setQuotationMetadata((prevMetadata: QuotationMetadata[]) => {
        return prevMetadata.map((item): QuotationMetadata => {
          // For array fields, update the group at the given index
          if (
            item.type === 'array' &&
            item.api_field === (api_field ? api_field.split('.')[0] : item.api_field) &&
            groupIndex !== undefined &&
            Array.isArray(item.value)
          ) {
            const groups = [...item.value];
            groups[groupIndex] = (groups as QuotationMetadata[][])[groupIndex].map((field) => {
              if (field.label === name || field.api_field === api_field) {
                return {
                  ...field,
                  value: value,
                  type: fieldType,
                };
              }
              return field;
            });
            return { ...item, value: groups as QuotationMetadata[][] };
          } else if (item.label === name || item.api_field === api_field) {
            return {
              ...item,
              value: value,
              type: fieldType,
            };
          }
          return item;
        });
      });
    },
    [setQuotationMetadata],
  );

  /**
   * Add a new group to an array field.
   */
  const addNewArrayItem = useCallback(
    (arrayFieldName: string) => {
      setQuotationMetadata((prevMetadata: QuotationMetadata[]) => {
        return prevMetadata.map((item): QuotationMetadata => {
          if (item.type === 'array' && item.label === arrayFieldName && Array.isArray(item.value)) {
            const arrayField = quotationFields?.find((field) => field.name === arrayFieldName);
            if (arrayField && arrayField.values) {
              const newGroup = (arrayField.values as QuotationField[]).map(
                (fieldValue): QuotationMetadata => ({
                  label: fieldValue.name,
                  value: '',
                  type: fieldValue.type as 'text' | 'number' | 'currency',
                  api_field: fieldValue.api_field_name,
                }),
              );
              return { ...item, value: [...item.value, newGroup] as QuotationMetadata[][] };
            }
          }
          return item;
        });
      });
    },
    [quotationFields],
  );

  /**
   * Remove a group from an array field.
   */
  const removeArrayItem = useCallback((arrayFieldName: string, groupIndex: number) => {
    setQuotationMetadata((prevMetadata: QuotationMetadata[]) => {
      return prevMetadata.map((item): QuotationMetadata => {
        if (item.type === 'array' && item.label === arrayFieldName && Array.isArray(item.value)) {
          const groups = [...item.value];
          groups.splice(groupIndex, 1);
          return { ...item, value: groups as QuotationMetadata[][] };
        }
        return item;
      });
    });
  }, []);

  /**
   * Render quotation fields using the local metadata state.
   */
  const renderQuotationFields = () => {
    // Use quotationMetadata (instead of orderMetadata?.fields) to drive rendering
    return quotationMetadata.map((metadata, index) => {
      // Try to match additional field settings from quotationFields if needed.
      const fieldSettings = quotationFields?.[index];
      return (
        <FormLayout.Group key={index}>
          <QuotationFieldComponent
            metadataField={metadata}
            name={metadata.label}
            type={metadata.type}
            resultModifierType={fieldSettings?.result_modifier_type || 'none'}
            fieldValue={fieldSettings?.result_modifier_value || '0'}
            values={fieldSettings?.values}
            api_field={metadata.api_field}
            handleSubtotal={handleSubtotal}
            handleMetadata={handleMetadata}
            addNewArrayItem={addNewArrayItem}
            removeArrayItem={removeArrayItem}
          />
        </FormLayout.Group>
      );
    });
  };

  /**
   * Handle quotation creation
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);

      // Calculate order pricing
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL ?? '/api'}/admin/orders/new/pricing`,
        {
          variant_id: variantId,
          metadata_fields: quotationMetadata,
          user_id: userId,
          cyber: variantId === process.env.REACT_APP_IMBA_CYBER_RISK_VARIANT_ID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );

      const subtotal_price = response.data?.data?.subtotal_price;
      onSave(subtotal_price ?? subtotal);

      // Append quotation metadata
      const tmp = [...quotationMetadata];
      tmp.push({
        label: 'quotation',
        value: subtotal_price ?? subtotal,
        type: 'currency',
        api_field: 'quotation',
      });

      if (orderMetadata) {
        orderMetadata.fields = tmp;
      } else {
        orderMetadata = {
          product: variantId,
          fields: tmp,
        };
      }

      onMetadata(orderMetadata);
      setSubtotal(subtotal_price ?? 0);
      setButtonSpinning(false);
      setActive(false);
    } catch (error) {
      console.error(error);
      setSaveError(true);
      setButtonSpinning(false);
    }
  }, [subtotal, quotationMetadata, orderMetadata, variantId, userId, onSave, onMetadata, setActive]);

  const saveErrorMarkup = saveError && (
    <Modal.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Modal.Section>
  );

  const existErrorMarkup = existError && (
    <Modal.Section>
      <Banner title="Esiste già un cliente associato a questo codice fiscale" status="critical" onDismiss={() => setExistError(false)}>
        <p>Si è pregati di controllare il codice fiscale se si desidera proseguire.</p>
      </Banner>
    </Modal.Section>
  );

  const modalMarkup = (
    <Modal
      open={active}
      onClose={() => {
        setSaveError(false);
        setActive(!active);
      }}
      title="Crea un nuovo preventivo"
      primaryAction={{
        content: 'Salva',
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => {
            setSaveError(false);
            setActive(!active);
          },
        },
      ]}
    >
      {saveErrorMarkup}
      {existErrorMarkup}
      <Modal.Section>
        <FormLayout>{renderQuotationFields()}</FormLayout>
      </Modal.Section>
    </Modal>
  );

  return modalMarkup;
};
